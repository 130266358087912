.project-description {
  &__text {
    font-size: 1rem;
    line-height: 1.5;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &__links {
    display: flex;
    align-items: center;
    margin-top: 50px;
  }

  &__link {
    &-wrapper {
      width: 55px;

      &:not(:last-child) {
        margin-right: 60px;
      }
    }

    &-icon {
      svg {
        path {
          transition: fill 0.3s $timing-function;
        }
      }
      &:hover {
        svg {
          path {
            fill: $yellow;
          }
        }
      }
    }
  }

  &__section {
    .title {
      margin-bottom: 50px;
    }

    &--membership {
      margin-top: 130px;
    }

    &--owning {
      margin-top: 220px;
      
      .title {
        margin-bottom: 120px;
      }

      .project-description {
        &__section-right {
          display: flex;
          flex-direction: column;
        }
        &__text {
          margin-top: 30px;
        }
      }
    }

    &--collect {
      margin-top: 150px;

      .project-description {
        &__section-left {
          display: flex;
          justify-content: center;
          flex-direction: column;
          position: relative;
          top: -60px;
        }
      }
    }

    &--screentime {
      margin-top: 95px;

      .project-description {
        &__section-right {
          display: flex;
          align-items: center;
        }
        &__text {
          position: relative;
          top: -60px;
          max-width: 70%;
        }
      }
    }

    &-content {
      display: flex;
      justify-content: space-between;
    }

    &-left {
      width: 50%;
      margin-right: 90px;
    }

    &-right {
      width: 50%;
    }

    &--movieshots {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 100px;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    &-element {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      padding: 25px 0;
      border: 1px solid $yellow;
      border-radius: 10px;
      min-height: 70px;
      transition: background-color .3s $timing-function;

      &-caption {
        font-size: 14px;
        text-transform: uppercase;
        color: $yellow;
        font-weight: bold;
        margin-bottom: 5px;
        text-align: center;
      }

      &-text {
        font-size: 1rem;
        color: $white;
        font-weight: bold;
        text-align: center;
      }

      &--active {
        background-color: rgba($yellow, .3);
      }
    }
  }

  &__image {
    &-wrapper {
      margin-top: 30px;
      width: 50%;
    }
  }

  .movie-shot {
    width: 100%;

    &__image {
      aspect-ratio: 16 / 9;

      &-wrapper {
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }
}
