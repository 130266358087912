.idea {
  position: relative;
  margin: clamp(10px, 5vh, 50px) auto 0;

  .arrow {
    &-wrapper {
      left: 0;
      bottom: 75px;
    }
  }

  &__buttons {
    margin-top: 30px;
  }

  &__title {
    font-size: 3.33rem;
    line-height: 1;
    margin: 0 0 60px;
    width: 100%;
    white-space: nowrap;
  }

  &__description {
    font-size: 1rem;
    line-height: 1.5;
  }

  &__right {
    width: 60%;
    position: relative;
    margin-left: 60px;

    &--full {
      width: 100%;
      margin-left: 0;
    }
  }

  &__image {
    max-width: 100%;

    &--main {
      min-height: 400px;
      height: 31vw;
      max-height: 600px;
      width: 100%;
      border: 50px solid $black;
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }

    &--shape {
      position: absolute;
      bottom: 0;
      max-height: 600px;
      height: 30vw;
      min-height: 120px;
      z-index: 3;
    }

    &-slider {
      position: relative;
      overflow: hidden;

      &::before {
        position: absolute;
        content: "";
        z-index: 2;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-width: clamp(20px, 5vw, 50px);
        border-style: solid;
        border-color: $black-dark;
      }

      .swiper-wrapper {
        // padding: clamp(20px, 5vw, 50px);
      }
    }
  }

  &__slide {
    &-image {
      height: 100%;
      width: 100%;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
