.movie-info {
  display: flex;
  flex-direction: column;
  margin-top: 250px;

  &__video {
    width: 100%;
  }

  &__description {
    font-size: clamp(18px, 1.25rem, 30px);
    margin-bottom: 110px;
    line-height: 1.5;
    text-align: justify;
  }

  &__section {
    display: flex;
    justify-content: space-between;
    font-size: clamp(16px, 1rem, 24px);
  }

  &__person {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &-link {
      display: inline-flex;
      color: $yellow;
      margin-left: 5px;
    }
  }

  &__people {
    margin-top: 2rem;
  }

  &__left {
    margin-right: 65px;
    width: 50%;
  }

  &__right {
    width: 50%;
  }
}
