.owning-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        &::before {
            content: "";
            height: 125px;
            width: 1px;
            background-color: $yellow;
            margin-bottom: 110px;
            margin-top: 200px;
        }
    }

    &__title {
        margin-bottom: 70px;
    }

    &__run-movieshots {
        margin-top: 400px;
    }

    &__awards {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        row-gap: 35px;
        column-gap: 50px;
        margin-top: 50px;
    }

    &__title {
        margin-bottom: 50px;
    }

    &__image {
        width: 100%;

        &-wrapper {
          margin-top: 30px;
          width: 100%;
        }
      }
}