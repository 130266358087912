.drop-details {
    margin-top: 100px;

    &__title {
        margin-bottom: 70px;
    }

    &__footer {
        display: flex;
        align-items: center;
        font-size: 30px;
        margin-top: 120px;
    }

    &__buttons {
        display: flex;
        margin-left: 30px;
    }

    &__table {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        column-gap: clamp(30px, 2vw, 70px);
        max-height: 520px;
        width: 100%;
        &-row-mobile {
            border-bottom: 1px solid rgba($white, .3);
        }

        &-row {
            display: flex;
            border-bottom: 1px solid rgba($white, .3);

            &--highlighted {
                background-color: rgba($grey, .1);
            }
        }

        &-cell {
            display: flex;
            align-items: center;
            padding: 0 10px;
            width: 50%;
            font-size: clamp(15px, 10vw, 20px);
            max-height: 50px;
            min-height: 50px;
            line-height: 1;

            .text {
                text-overflow: ellipsis;
            }

            .link {
                padding: 0;
                text-overflow: ellipsis;
                overflow: hidden;
                text-decoration: underline;
            }

            &:not(:last-child) {
                border-right: 1px solid rgba($white, .3);
            }
        }
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;

        &:not(:last-child) {
            margin-right: 20px;
        }

        &-icon {
            width: 30px;
            height: 30px;
            margin-right: 10px;
        }
    }
}