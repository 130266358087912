// Main Colors Variables;
$lights_out: black;
$lights_on: white;
$curtain_color: #7C0A02;
$curtain_shadows: darken($curtain_color, 30);
$curtain_highlights: lighten($curtain_color, 30);
$curtain_3d: repeating-linear-gradient(to left,
    $curtain_color 4vw,
    $curtain_shadows 8vw,
    $curtain_highlights 10vw
);

// General page hover controller
.stage {
    &__lights {
        background: $lights_out;
        overflow: hidden;
        position: relative;

        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            transition: .3s $timing-function;
        }

        &::before {
            background-color: $black-dark;
            z-index: 1;
        }

        &::after {
            background-color: rgba($black-dark, .5);
            z-index: 3;
        }
    }

    &__description {
        color: $lights_out;
        transition: color 3s ease;

        &:hover {
            color: $lights_on;
        }
    }

    &__button {
        position: absolute;
        z-index: 4;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);

        padding: 20px;
        font-size: 16px;
        color: $white;
        text-transform: uppercase;
        background-color: unset;
        cursor: pointer;

        border: 1px solid $white;
        transition: .3s $timing-function;

        &:hover {
            background-color: $white;
            color: $black-dark;
        }
    }

    &__image {
        transition: 2s $timing-function;
    }

    // curtains open animations triggers
    &--open {
        div {
            &.curtain {
                &.right {
                    -webkit-transform-origin: 120% bottom;
                    transform-origin: 120% bottom;
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                }

                &.left {
                    -webkit-transform-origin: -120% top;
                    transform-origin: -120% top;
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                }
            }
        }
        .stage {
            &__image {
                transform: none;
            }

            &__lights::before,
            &__lights::after {
                opacity: 0;
            }
        }
    }
}

// Curtains styles
.curtain {
    width: 100%;
    position: absolute;
    top: -10%;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    margin: auto;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transition: -webkit-transform 6s ease;
    transition: transform 6s ease;
    transition: transform 6s ease, -webkit-transform 6s ease;

    // Original position animation
    &.left {
        -webkit-transform-origin: -120% top;
        transform-origin: -120% top;
    }

    &.right {
        left: 50%;
        -webkit-transform-origin: 120% top;
        transform-origin: 120% top;
    }

    // Curtain nodes
    &__node {
        width: 10%;
        height: 100%;
        background: $curtain_3d;
        background-size: 100% 100%;
        display: inline-block;
        -webkit-transform-origin: 0 0%;
        transform-origin: 0 0%;
        -webkit-transform: rotate(3deg);
        transform: rotate(3deg);
        -webkit-animation: curtainNode 2s ease infinite;
        animation: curtainNode 2s ease infinite;

        // styles for the curtain nodes
        @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
                -webkit-animation-delay: (-0.1s * $i);
                animation-delay: (-0.1s * $i);
            }
        }
    }
}

// Swing effect keyframes
@-webkit-keyframes curtainNode {
    50% {
        -webkit-transform: rotate(-1deg);
        transform: rotate(-1deg);
    }
}

@keyframes curtainNode {
    50% {
        -webkit-transform: rotate(-1deg);
        transform: rotate(-1deg);
    }
}