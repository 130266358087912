@keyframes fade {
  from {
    opacity: 1;
  }
}

.spinner-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $black-1;

  &.loaded {
    animation: fade 600ms ease-in;
    opacity: 0;
  }

  svg {
    color: $yellow;
  }
}
