.instructions {
    display: flex;
    align-items: center;
    justify-content: center;

    &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: clamp(10px, 5vh, 50px) auto 0;
    }

    &__title {
        text-align: center;
        margin: 100px 0;
    }

    &__block {
        display: flex;
        flex-direction: column;
        align-items: center;

        padding: 20px;
        border: 1px solid $white;
        width: calc((100% / 3) - 40px);
        min-height: 320px;

        &:not(:last-child) {
            margin-right: 60px;
        }

        &-title {
            text-transform: uppercase;
            color: $yellow;
            margin-bottom: 20px;
        }

        &-description {
            text-align: center;
            line-height: 1.5;

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            .link {
                display: inline;
                text-decoration: underline;
                
                &:before {
                    display: none;
                }
            }
        }
    }
}