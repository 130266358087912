/* Animations */
@keyframes rollIn {
	from {
		transform: translateY(-100%);
	}

	to {
		transform: translateY(0);
	}
}

@keyframes fade {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes blur {
	0% {
		filter: blur(6px);
	}

	50% {
		filter: blur(10px);
	}

	100% {
		filter: blur(6px);
	}
}

@keyframes pulse {
	0% {
		transform: scale(.8);
	}

	50% {
		transform: scale(1);
	}

	100% {
		transform: scale(.8);
	}
}