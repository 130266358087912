.dropdown {
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .item-hidden {
    display: none;
  }

  &-open {
    .dropdown-arrow {
      transform: rotate(180deg);
    }
  }

  &-arrow {
    margin-left: 9px;
    width: 18px;
    height: 18px;
  }

  &-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 1rem;
    font-weight: bold;
    color: $white;
    background-color: $black-1;

    span {
      font-size: 11px;
      font-weight: normal;
    }
  }

  &-menu {
    position: absolute;
    list-style-type: none;
    margin: 5px 0;
    padding: 1rem;
    right: 0;
    color: $black;
    background-color: $black-1;
    border-radius: 1rem;
    border: 1px solid $yellow;
    width: 150%;

    li {
      margin: 0;
      color: $black;
      background-color: $black-1;

      &:first-child  button {
        border-top-right-radius: 1rem;
        border-top-left-radius: 1rem;
      }

      &:last-child  button {
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
      }
    }

    li > button {
      width: 100%;
      height: 100%;
      text-align: center;
      background-color: #262626;
      color: $white;
      border: none;
      padding: 1rem;
      margin: 1px 0;
      font-size: 16px;
      cursor: pointer;
    }

    li > button:hover {
      background-color: #333333;
    }
  }
}
