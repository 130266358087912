.movie-shots {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 6rem 0;

  &__title {
    margin: 50px 0;
  }

  .swiper-slide {
    width: auto;
  }

  .swiper-slide:not(.swiper-slide-active) {
    .movie-shot-wrapper {
      &:hover {
        .movie-shot__image-wrapper {
          border-color: rgba($yellow, 0.5);
        }
      }
    }
  }

  .movie-shot {
    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__info {
      color: $white;
      opacity: 1;

      &--title {
        font-weight: bold;
      }

      &-wrapper {
        margin-top: 20px;
      }
    }

    &__image {
      min-height: 100px;
      height: 30vw;
      max-height: 300px;

      &-wrapper {
        width: 100%;
        border: 20px solid $black-dark;
        transition: border-color 0.3s $timing-function;
      }
    }
  }

  .swiper {
    &-slide {
      &-active {
        .movie-shot {
          &__image-wrapper {
            border-color: $yellow;
          }
        }
      }
    }
  }
}
