.faq {
  margin-top: clamp(70px, 10vw, 158px);
  margin-bottom: clamp(70px, 10vw, 185px);

  &__image {
    width: 100%;
  }

  &__title {
    margin-bottom: 150px;
    text-align: center;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__questions {
    display: flex;
    flex-direction: column;
  }

  &__question {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba($white, 0.3);
    padding: 1.667rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s $timing-function;

    &:first-child {
      border-top: 1px solid rgba($white, 0.3);
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-title {
      line-height: 1.5;
      margin-right: 10px;
    }

    &-link {
      display: inline-flex;
      white-space: initial;
    }

    &-answer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      opacity: 0;
      max-height: 0; 
      overflow: hidden;
      line-height: 1.5;

      &-image {
        max-width: 100%;
        height: auto;
        margin-top: 20px;
      }
    }

    &-icon {
      opacity: 0.5;
      width: 35px;
      height: 35px;
      transition: all 0.3s $timing-function;
      cursor: pointer;
    }

    &:hover {
      background-color: rgba($white, 0.1);
      .faq__question {
        &-icon {
          opacity: 1;
        }
      }
    }

    &--open {
      .faq__question {
        &-answer {
          margin-top: 30px;
          max-height: 1200px;
          overflow: hidden;
          opacity: 1;
          transition: opacity 0.3s $timing-function;
        }

        &-icon {
          transform: rotateZ(45deg);
        }
      }
    }
  }

  &__left,
  &__right {
    width: 50%;
  }

  &__left {
    margin-right: 60px;
  }
}
