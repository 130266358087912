.collection {
  min-height: calc(100vh - 400px);
  margin-top: 125px;

  &-page {
    &--videoPlaying {
      background-color: $bg-color;
      color: $bg-color;

      &::before {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        content: '';
        z-index: 3;
        background-color: rgba($bg-color, 0.8);
      }

      // .subtitle {
      //     color: $bg-color;
      //     &::before {
      //         background-color: $bg-color;
      //     }
      // }

      // & .collection__video-wrapper {
      //     transition: none;
      //     border-color: $black-dark;
      // }
    }
  }

  &__nft-video {
    width: 100%;
    max-width: 900px;
    object-fit: cover;
  }

  &__video {
    aspect-ratio: 16 / 9;
    max-width: 900px;

    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      z-index: 4;
      max-width: 900px;
      width: 100%;
      // border: clamp(20px, 5vw, 40px) solid transparent;
      transition: 0.3s ease-in-out, border-color 0s ease-in-out;

      .react-aspect-ratio-placeholder {
        width: 100%;
      }
    }

    &-close {
      border: none;
      width: 40px;
      height: 40px;
      display: flex;
      background-color: unset;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -80px;
      right: -80px;
      z-index: 100;
      cursor: pointer;
      transition: 0.3s $timing-function;

      &-icon {
        color: $white;
        width: 20px;
        height: 20px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
  }

  &__button {
    margin-top: 30px;
    align-self: flex-end;
  }

  &__empty {
    &,
    &-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-title {
      text-align: center;
      margin-bottom: 0;
    }

    .button {
      white-space: nowrap;
    }

    &-footer {
      &::before {
        content: '';
        height: 125px;
        width: 1px;
        background-color: $yellow;
        margin-bottom: 60px;
        margin-top: 50px;
      }
    }
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: clamp(30px, 2vw, 85px);

    &-title {
      margin: 0;
    }

    &-title-public {
      font-size: clamp(20px, 2vw, 35px);
    }

    &-info {
      display: flex;
      font-size: clamp(20px, 2vw, 35px);

      &-wrapper {
        display: flex;
        flex-direction: column;
      }

      &-title {
        font-weight: 300;
      }

      &-value {
        margin-left: 10px;
        font-weight: bold;
      }
    }
  }

  &__movieshots {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 50px;
    margin-top: clamp(50px, 2vw, 85px);
    margin-bottom: clamp(60px, 2vw, 165px);

    &--empty {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__movieshot {
    position: relative;
    cursor: pointer;

    &--firstInTheRow,
    &--lastInTheRow {
      &::after {
        position: absolute;
        content: '';
        left: -25px;
        top: -15px;
        bottom: -15px;
        right: -25px;
        z-index: -1;
        transition: background-color 0.3s $timing-function;
      }
    }

    &--firstInTheRow {
      &::after {
        left: -100%;
        right: calc(100% + 25px);
      }
    }

    &--lastInTheRow {
      &::after {
        right: -100%;
        left: calc(100% + 25px);
      }
    }

    &--inSequence {
      &::before {
        position: absolute;
        content: '';
        left: -25px;
        top: -15px;
        bottom: -15px;
        right: -25px;
        z-index: -1;
        transition: background-color 0.3s $timing-function;
      }

      &.hovered {
        &.collection__movieshot--firstInTheRow,
        &.collection__movieshot--lastInTheRow {
          &::after {
            background-color: rgba(#8e8e8e, 0.2);
          }
        }

        &::before {
          background-color: rgba(#8e8e8e, 0.2);
        }
      }
    }

    &-stage {
      .stage {
        &__lights {
          position: relative;
          height: 220px;

          &::before,
          &::after {
            display: none;
          }
        }
      }
    }

    &-footer {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $white;
    }

    &-text {
      font-size: clamp(14px, 2vw, 18px);
      color: rgba($white, 0.5);
      transition: 0.3s $timing-function;
      font-weight: bold;

      &:hover {
        color: $yellow;
      }
    }

    &-play {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border: 1px solid $white;
      opacity: 0.5;
      background-color: unset;
      transition: 0.3s $timing-function;
      cursor: pointer;

      &-icon {
        fill: $white;
        transition: 0.3s $timing-function;
      }

      &:hover {
        opacity: 1;
        border-color: $yellow;

        .collection__movieshot-play-icon {
          fill: $yellow;
        }
      }
    }
  }
}
