.metamask-modal {
    display: flex;
    flex-direction: column;
    background-color: $white;
    color: $black;

    box-shadow: 0 3px 6px rgba($black-dark, .16);
    border-radius: 6px;
    padding: clamp(20px, 7vw, 40px);
    max-width: 600px;
    width: 100%;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__subtitle {
        font-size: 14px;
        color: $red;

        &::before {
            background-color: $red;
        }
    }

    &-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
        width: 100vw;
        padding: 30px;
    }

    &__title {
        font-weight: bold;
        margin-top: 40px;
        font-size: 30px;
    }

    &__content { 
        margin-top: 20px;
        line-height: 1.5;

        .link {
            color: $black;
            border-bottom: 1px solid $black;
            transition: .3s $timing-function;
            padding: 0;
            
            &::before {
                display: none;
            }

            &:hover {
                opacity: .8;
            }
        }
    }

    &__button {
        align-self: flex-start;
        margin-top: 40px;
    }
}