.free-claim {
  min-height: calc(100vh - 400px);
  margin-top: 125px;
  margin-bottom: -2rem;

  &-link {
    text-decoration: underline;
    color: $yellow;
    position: relative;
    cursor: pointer;
  }

  &__title {
    margin-top: 50px;
  }

  &-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 5rem;
  }

  &-subcontent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &-text {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    align-self: stretch;
    padding: 1rem 4rem;

    font-size: 1rem;
    line-height: 2;

    .text-title {
      font-size: 1.8rem;
      margin-bottom: 0.5rem;
    }
  }

  &-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-image {
    flex: 2;
    align-self: center;
  }

  &-teaser {
    margin-top: 7rem;
  }

  .center {
    text-align: center  ;
  }

  .block {
    margin-bottom: 1rem;
  }

  .eth-info {
    font-size: 14px;
    margin-top: 5rem;
    padding: 0 4rem;
  }

  @media (max-width: 991.98px) {
    &-content {
      flex-direction: column;
      margin-top: 3rem;
    }

    &-text {
      padding: 0;
  
      font-size: 1.2rem;
      line-height: 1.5;
    }

    &-image {
      padding: 0;
    }

    &-button {
      width: 100%;
      margin: 2rem auto;
    }

    .eth-info {
      font-size: 1rem;
      padding: 0 30px;
    }
  }
}
