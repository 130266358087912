.movie {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-repeat: no-repeat;
  background-position: center;
  padding: clamp(10px, 5vh, 50px);

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    &::before {
      position: absolute;
      content: attr(data-title);
      left: 0;
      top: 50%;

      font-weight: bold;
      font-size: 3.33rem;
      transform: rotateZ(180deg) translate3d(-50%, 50%, 0);
      writing-mode: vertical-rl;
      margin-right: 75px;
      letter-spacing: 5px;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }

  &__bottom {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  &-wrapper {
    .react-parallax-bgimage {
      aspect-ratio: 128 / 69;
    }
  }

  &__content {
    display: flex;
    background-color: $white;
    padding: 2rem;
    width: 100%;
  }

  &__description {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    &-wrapper {
      display: flex;
      flex-direction: column;
    }

    &-icon {
      min-height: 20px;
      max-height: 40px;
      min-width: 40px;

      height: 100%;
      width: 100%;
    }

    &-text {
      font-size: clamp(16px, 0.85rem, 20px);
      line-height: 1.5;
      margin-top: 30px;
    }

    &-link {
      transition: opacity .3s $timing-function;
      &:hover {
        opacity: .5;
      }
    }
  }

  &__garanties {
    display: flex;
    margin-top: 50px;
  }

  &__garanty {
    font-size: clamp(14px, 0.85rem, 18px);
    white-space: nowrap;
    &:not(:last-child) {
      margin-right: 10px;
      &::after {
        content: "//";
        margin-left: 10px;
      }
    }
  }

  &__text {
    font-size: clamp(16px, 0.85rem, 20px);
  }

  &__title {
    font-size: clamp(18px, 1.25rem, 26px);
    text-transform: uppercase;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
  }

  &__cover {
    object-fit: cover;
    object-position: 30% 0%;

    height: 100%;
    width: 100%;
    aspect-ratio: 1 / 1;

    &-wrapper {
      width: clamp(200px, 100%, 390px);
      height: clamp(200px, 100%, 390px);
      padding: 5px;
      box-shadow: 0 3px 30px rgba($black, 0.16);
      margin-right: 60px;
      aspect-ratio: 1 / 1;
    }
  }

  &__number {
  }

  &__content {
    color: $black;
  }

  &__award {
    &-slide {
      width: clamp(40px, 40vw, 75px) !important;
      height: clamp(40px, 40vw, 75px) !important;
    }

    &s {
      height: 75px;
      width: clamp(200px, 100%, 900px);
      position: relative;
      top: -7px;
      margin-top: 30px;
      cursor: grab;

      .swiper {
        &-wrapper {
          padding-bottom: 7px;

        }

        & .swiper-scrollbar {
          height: 8px;
        }
      }
    }

    &-image {
      width: 100%;
      height: 100%;
    }
  }
}