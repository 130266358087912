.award-reveal {
  display: flex;
  flex-direction: column;
  color: $black-dark;

  &__stage {
    &,
    .stage__lights,
    .stage__description {
      // height: 100%;
      // width: 100%;
    }
  }

  &__video {
    aspect-ratio: 16 / 9;
    max-width: 900px;

    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      z-index: 4;
      max-width: 900px;
      width: 100%;
      border: clamp(20px, 5vw, 40px) solid transparent;
      transition: 0.3s ease-in-out, border-color 0s ease-in-out;

      .react-aspect-ratio-placeholder {
        width: 100%;
      }
    }

    &-close {
      border: none;
      width: 40px;
      height: 40px;
      display: flex;
      background-color: unset;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -80px;
      right: -80px;
      z-index: 100;
      cursor: pointer;
      transition: 0.3s $timing-function;

      &-icon {
        color: $white;
        width: 20px;
        height: 20px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &-wrapper {
    background-color: $white;
    padding: 65px 0;
    min-height: 100vh;
    margin-top: 125px;

    &--videoPlaying {
      background-color: $bg-color;
      color: $bg-color;

      &::before {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        content: '';
        z-index: 3;
        background-color: rgba($bg-color, 0.8);
      }

      .subtitle {
        color: $bg-color;
        &::before {
          background-color: $bg-color;
        }
      }

      .award-reveal__video-wrapper {
        transition: none;
        border-color: $black-dark;
        background-color: $black-dark;
      }
    }
  }

  &__text {
    font-size: clamp(30px, 2vw, 40px);
    margin-bottom: 30px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    margin-top: 30px;
    font-size: clamp(40px, 7vw, 70px);
    margin-bottom: 15px;
  }

  &__movieshot {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    &--open {
      .award-reveal {
        &__description-wrapper {
          visibility: visible;
          transform: none;
        }

        &__tags {
          opacity: 1;
          transform: none;
          max-height: 315px;
        }
      }
    }

    &--no-subtitle {
      .award-reveal__title {
        margin-top: 0;
      }
      .subtitle {
        display: none;
      }
    }

    &:not(:last-child) {
      margin-bottom: 100px;
    }
  }

  &__left {
    width: 50%;
  }

  &__address {
    display: inline-block;
    text-decoration: underline;
    margin-left: 10px;
    color: $black;
    transition: 0.3s $timing-function;

    &:hover {
      color: $yellow;
    }
  }

  &__footer {
    margin-top: 100px;
    width: calc(50% - 85px);
    align-self: flex-end;
  }

  &__owner {
    font-size: clamp(14px, 2vw, 16px);
  }

  &__link {
    color: $black-dark;
    font-weight: normal;
    text-decoration: underline;

    &::before {
      display: none;
    }
  }

  &__icon {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(50% - 85px);
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
  }

  &__close-button {
    align-self: flex-start;
    background-color: unset;
    border: none;
    font-size: 30px;
    transition: 0.3s $timing-function;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &__tags {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 15px;
    max-height: 0px;
    overflow-y: scroll;
    padding-right: 10px;

    opacity: 0;
    transform: translateY(40px);
    transition: 0.6s $timing-function;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $black-dark;
      border-radius: 5px;
    }
  }

  &__tag {
    border: 1px solid $black-dark;
    &-label {
      color: $black-dark;
    }

    &-value {
      color: $black-dark;
    }
  }

  &__description {
    font-size: clamp(14px, 10vw, 16px);
    white-space: nowrap;

    &:first-child {
      font-weight: bold;
    }

    &:not(:last-child) {
      &::after {
        content: '|';
        margin: 0 10px;
      }
    }

    &-wrapper {
      display: flex;
      margin-bottom: 60px;
      margin-top: 10px;
      visibility: hidden;
      transition: 0.6s $timing-function;
      transform: translateX(40px);
    }
  }

  &__button {
    display: flex;
    align-items: center;
    border: 1px solid $black-dark;
    transition: 0.3s $timing-function;
    color: $black;
    text-decoration: none;
    &:hover {
      opacity: 0.5;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 991.98px) {
    &__movieshot {
      flex-direction: column;
    }
  }
}
