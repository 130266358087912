.minted-shot {
    overflow: hidden;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    &__image {
        height: 100%;
        width: 100%;

        &-wrapper {
            width: 100%;
            position: relative;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: clamp(15px, 2vw, 30px) clamp(22px, 2vw, 44px);
        background-color: $white;
        color: $black-dark;
        min-height: clamp(195px, 2vw, 205px);
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        font-size: clamp(12px, 2vw, 14px);
    }

    &__label {
        color: $yellow;
    }

    &__date-time {
        color: $grey;
    }

    &__title {
        margin-bottom: 15px;
        font-size: clamp(18px, 2vw, 25px);
    }

    &__description {
        display: flex;

        &-text {
            font-size: 16px;
            white-space: nowrap;

            &:not(:last-child) {
                &::after {
                    content: "|";
                    margin: 0 15px;
                }
            }
        }
    }

    &__owner {
        &-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: clamp(12px, 2vw, 16px);
            margin-top: 10px;
        }

        &-address {
            margin-left: 5px;
            color: $yellow;
            border-bottom: 1px solid $yellow;
            max-width: 80%;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    &__opensea {
        &-link {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 1px solid $grey;
            width: 45px;
            height: 45px;
            min-width: 45px;
            min-height: 45px;
            margin-left: 15px;
            transition: .3s $timing-function;

            &:hover {
                opacity: .8;
            }
        }

        &-image {
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
        }
    }

    &__button {
        margin-top: 10px;
        display: inline-flex;
        align-self: flex-start;
    }
}