:root {
    --tag-value-width: 90px;
}

.tag, .quote {
    &s {
        overflow-x: hidden;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid $black-dark;
    border-radius: 4px;
    padding: 12px 18px;
    max-width: var(--tag-width);
    text-decoration: none;
    transition: .3s $timing-function;
    cursor: pointer;

    &:hover {
        background-color: $yellow;
        border-color: $black-dark;
        
        .tag, .quote {
            &__value,
            &__label {
                color: $black-dark;
            }
        }
    }

    &__label {
        font-size: 9px;
        color: $black-dark;
        margin-bottom: 5px;
        transition: .3s $timing-function;
        text-align: center;
    }

    &__value {
        font-size: 15px;
        color: $black-dark;
        white-space: nowrap;
        font-weight: bold;
        transition: .3s $timing-function;

        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: calc(var(--tag-value-width));
        overflow: hidden;
        line-height: 1.4;
    }
}

.quote {
    grid-column-start: 1;
    grid-column-end: -1;
    max-width: 100%;

    &__value {
        max-width: 100%;
    }
}