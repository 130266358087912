.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  z-index: 1;

  .decor {
    position: absolute;
    right: 0;
    bottom: -200%;
    width: 640px;
    z-index: -1;
    opacity: 0.5;
  }

  &-wrapper {
    background-color: $black-dark;
    padding: 70px 0;
    margin-top: 2rem;
    overflow: hidden;

    .arrow {
      &-wrapper {
        position: static;
        transform: rotateZ(180deg);

        &:hover {
          transform: rotateZ(180deg) translateY(10px);
        }
      }
    }
  }

  &-newsletter {
    &__text {
      font-size: 26px;
      font-weight: bold;
    }

    &__error, &__success {
      color: $red;
      margin-bottom: 1rem;
      font-weight: bold;
    }

    &__success {
      color: $green;
    }

    &__consent {
      font-size: 14px;
      margin-bottom: 2rem;

      a {
        color: white;
      }
    }

    &__submit {
      cursor: pointer;
      font-size: 26px;
      background-color: transparent;
      color: $yellow;
      border: 2px solid $yellow;
    }

    &__form {
      input {
        margin: 15px 0;
        font-size: 24px;
        text-align: left;
        border: none;
        border-bottom: 2px solid $yellow;
        font-weight: normal;
        border-radius: unset;
        background: transparent;
        color: $yellow;

        &::placeholder {
          color: white;
          opacity: 0.85;
        }

        &:-ms-input-placeholder {
          color: white;
        }

        &::-ms-input-placeholder {
          color: white;
        }
      }
    }

    &__input-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 2rem;
    }
  }

  &__social {
    &-link {
      &-wrapper {
        &:not(:last-child) {
          margin-right: 30px;
        }
      }

      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;

        svg {
          width: 100%;

          path {
            transition: fill 0.3s $timing-function;
          }
        }

        &:hover {
          svg {
            path {
              fill: $yellow;
            }
          }
        }
      }
    }

    &-links {
      display: flex;
      align-items: center;
      margin-right: 30px;
    }
  }

  &__logo {
    margin-bottom: 30px;
  }

  &__section {
    display: flex;
    align-items: flex-end;

    color: $white;

    &-title {
      font-size: 24px;
      line-height: 1;
    }

    &-text {
      font-size: 16px;
      margin: 35px 0 0 0;
      line-height: 1.7;
    }

    &--vertical {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__button {
    display: flex;
    align-items: center;

    &-icon {
      width: 30px;
      height: 30px;

      margin-right: 10px;
    }
  }

  &__link {
    text-decoration: none;
    color: $white;

    &-icon {
      width: 30px;
      height: 30px;

      svg {
        path {
          transition: fill 0.3s $timing-function;
        }
      }

      &:hover {
        svg {
          path {
            fill: $yellow;
          }
        }
      }
    }

    &s {
      display: flex;
      list-style: none;
      padding-left: 0;
      margin: 0;

      &--socials {
        margin-right: 50px;

        .footer__link-wrapper {
          &:not(:last-child) {
            margin-right: 25px;

            &::after {
              display: none;
            }
          }
        }
      }
    }

    &-wrapper {
      font-weight: 500;
      font-size: 16px;

      &:not(:last-child) {
        margin-right: 5px;

        &::after {
          display: inline-block;
          content: '/';
          color: $white;
          margin-left: 5px;
        }
      }
    }
  }

  @media (max-width: 991.98px) {
    &-newsletter {
      &__text {
        text-align: center;
        font-size: 24px;
        margin-bottom: 1rem;
      }

      &__error, &__success {
        text-align: center;
      }

      &__form {
        input {
          margin-top: 15px;
          font-size: 20px;
          text-align: center;
        }
      }

      &__input-wrapper {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        margin-bottom: 1rem;
      }
    }
  }
}
