.press {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  &__heading {
    font-weight: 300;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(3, minmax(150px, 360px));
    row-gap: 3rem;
    column-gap: 4vw;
    justify-content: space-between;
    flex-wrap: wrap;

    &-slider {
      width: 100%;
    }
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;

    &-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.5rem;
    }

    &-link {
      display: flex;
      width: 30px;
      height: 30px;
      min-width: 30px;
      min-height: 30px;
      cursor: pointer;
      svg {
        path {
          transition: fill 0.3s $timing-function;
        }
      }
      &:hover {
        svg {
          path {
            fill: $yellow;
          }
        }
      }
    }

    &-image {
      max-width: 100%;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;

      &-wrapper {
        width: 100%;
        max-height: 450px;
        height: 10vw;
        min-height: 200px;
      }
    }

    &-title {
      font-size: 1rem;
      font-weight: bold;
    }

    &-subtitle {
      font-size: 0.8rem;
      font-weight: 400;
      line-height: 1.2;
      margin-top: 0.5rem;
    }
  }

  &-download {
    filter: invert(95%) sepia(10%) saturate(276%) hue-rotate(274deg)
      brightness(119%) contrast(100%);
  }

  &-show-all {
    margin: 2rem 0;
  }

  @media (max-width: 991.98px) {
    &__items {
      grid-template-columns: repeat(2, minmax(150px, 360px));
    }

    &__item {
      &-image {
        object-fit: cover;
      }
    }
  }

  @media (max-width: 767.98px) {
    &__items {
      grid-template-columns: repeat(1, minmax(150px, 500px));
    }

    &__item {
      &-title {
        font-size: 1.2rem;
      }

      &-subtitle {
        font-size: 1rem;
      }
    }
  }
}
