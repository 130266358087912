.how-to-mint {
    display: flex;
    width: 100%;

    &__title {
        margin-bottom: 70px;
    }

    &__description {
        list-style: disc;

        &-item {
            line-height: 1.5;
            position: relative;
            left: 25px;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

    &__video {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        aspect-ratio: 16 / 9;
        border: 1px solid $yellow;
        position: relative;
    }

    &__play-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        border: 2px solid $white;
        border-radius: 50%;
        background-color: unset;
        transition: .3s $timing-function;
        cursor: pointer;

        &:hover {
            opacity: .8;
        }
    }

    &__left,
    &__right {
        display: flex;
        flex-direction: column;
        width: calc(50% - 55px);
    }

    .button {
        margin-top: 275px;
    }
}