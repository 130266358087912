.not-found {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: clamp(10px, 5vh, 50px) auto 0;
  line-height: 1.5;

  &__title {
    font-size: 4.3rem;
    line-height: 1;
    margin: 0 0 2rem;
    text-align: center;
  }

  &__subtitle {
    font-size: 1.6rem;
    line-height: 1;
    margin: 0 0 2rem;
  }
}
