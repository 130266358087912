.movie-teaser {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  border-radius: 12px;
  padding: 0.5rem;
  max-width: 980px;
  background-color: $white;
  color: $black;

  img {
    max-height: 330px;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 15px 0;
    justify-content: space-between;
  }

  .movie-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

  }

  .text {
    font-size: clamp(15px, 2.55vw, 28px);
  }

  .text2 {
    font-size: clamp(1.2rem, 1.3rem, 1.75rem);
  }

  .movie-strong {
    font-family: 'Metric', Arial, Helvetica, sans-serif;
    font-size: clamp(1.75rem, 1.8rem, 2.75rem);
  }

  .small {
    font-family: 'Metric', Arial, Helvetica, sans-serif;
    font-size: clamp(0.75rem, 0.9rem, 1.75rem);
  }

  .icon {
    height: 2rem;
    width: auto;
  }
}

@media (max-width: 991.98px) {
  .movie-teaser {
    flex-direction: column;

    margin: 0 50px;

    img {
      max-height: inherit;
      width: 100%;
    }
  }
}