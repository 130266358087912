.community {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 3rem;
  margin: clamp(10px, 5vh, 50px) auto 0;
  max-width: 1200px;

  > *,
  .title,
  .bottom-text {
    flex: 1 0 100%;
    width: 100%;
  }

  .bottom-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 2rem;
    gap: 1rem;
  }

  .community_title {
    text-align: center;

    &--wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.25rem 0;

      h2 {
        font-size: 1.4rem;
      }
    }
  }

  &__btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__discord-icon {
    margin-left: 4px;
    width: 32px;
    height: 32px;
  }
}

@media (min-width: 768px) {
  .community {
    flex-direction: row;

    > * {
      flex: 0 0 calc(33.333% - 3rem);
    }

    .bottom-text {
      flex-direction: row;
    }
  }
}

@media (max-width: 1199.98px) { 
  .community {
    > * {
      flex: 0 0 calc(50% - 3rem);
    }
  }
}
