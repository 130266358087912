.wallet {
  position: relative;
  margin: clamp(10px, 5vh, 50px) auto 0;
  line-height: 1.5;

  &__strong {
    font-weight: bold;
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    column-gap: 3rem;
  }

  &__left {
    flex: 1;
    order: 1;
  }

  &__right {
    display: flex;
    flex: 1;
    align-self: stretch;
    order: 2;

    img {
      object-position: center;
      object-fit: contain;
    }
  }

  &__title {
    font-size: 3.3rem;
    line-height: 1;
    margin: 0 0 2rem;
    text-align: center;
  }

  &__subtitle {
    font-size: 2.2rem;
    line-height: 1;
    margin: 0 0 2rem;
  }

  &__description {
    font-size: 1.1rem;
    margin: 0 0 2rem;
  }

  &__counter {
    margin: 0;
    padding: 0;
    list-style-type: none;
    list-style-type: decimal;
    list-style-position: outside;

    li {
      counter-increment: step-counter;
      padding: 0 0 0 2em;
      text-indent: -2em;
      list-style-type: none;
      margin-bottom: 40px;
    }

    li::before {
      content: counter(step-counter);
      font-weight: bold;
      background-color: $yellow;
      color: white;
      padding: 0.1rem 0.3rem;
      border-radius: 5px;
      margin-right: 0.8em;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: -2rem;
  }

  &__wrapper:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url(/assets/images/all-movieshots.webp);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.5;
    filter: grayscale(0.9);
  }

  &__card {
    border: 2px solid $yellow;
    border-radius: 0;
    color: $white;
    opacity: 1;
    background-color: $black-1;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1.5rem;
    margin: 6rem 0;
    width: 85%;

    .icon {
      height: 3rem !important;
    }
  }

  @media (max-width: 991.98px) {
    &__content {
      flex-direction: column;
    }

    &__left {
      order: 2;
    }

    &__right {
      align-self: center;
      order: 1;

      img {
        padding: 0 5rem;
      }
    }
  }

  @media (min-width: 767.98px) {
    &__card {
      width: 60%;
      padding: 2rem 5rem;
    }
  }

  @media (max-width: 575.98px) {
    &__right {
      img {
        padding: 0;
      }
    }
  }
}
