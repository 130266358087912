.team {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  &-wrapper {
    margin: clamp(10px, 5vh, 50px) auto 0;
  }

  &__heading {
    font-weight: 300;
    margin-bottom: 82px;
  }

  &__members {
    display: grid;
    grid-template-columns: repeat(3, minmax(150px, 360px));
    row-gap: 100px;
    column-gap: 4vw;
    justify-content: space-between;
    flex-wrap: wrap;

    &-slider {
      width: 100%;
    }
  }

  &__member {
    max-width: 360px;
    width: 100%;
    display: flex;
    flex-direction: column;

    &-content {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 30px;
    }

    &-link {
      display: flex;
      width: 30px;
      height: 30px;
      cursor: pointer;
      svg {
        path {
          transition: fill 0.3s $timing-function;
        }
      }
      &:hover {
        svg {
          path {
            fill: $yellow;
          }
        }
      }
    }

    &-image {
      max-width: 100%;
      height: 100%;
      width: 100%;
      object-fit: cover;

      &-wrapper {
        max-width: 360px;
        width: 100%;
        max-height: 450px;
        height: 22vw;
        min-height: 200px;
      }
    }

    &-fullname {
      text-decoration: underline;
      text-decoration-color: $black;
      font-size: 1rem;
      font-weight: 500;
    }

    &-position {
      font-size: 0.8rem;
      font-weight: 400;
      line-height: 1.5;
      margin-top: 10px;
      text-transform: uppercase;
    }
  }
}
