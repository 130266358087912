.learn-more {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &::before {
        content: "";
        height: 125px;
        width: 1px;
        background-color: $yellow;
        margin-bottom: clamp(70px, 10vw, 165px);
        margin-top: clamp(70px, 10vw, 165px);
    }

    &-center {
        text-align: center;
    }

    &__title {
        margin-bottom: 70px;
    }

    &__content {
        display: flex;
        width: 100%;
        margin-bottom: 4rem;
    }

    &__description {
        line-height: 1.5;
    }

    &__video {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        aspect-ratio: 16 / 9;
        border: 1px solid $yellow;
        position: relative;
    }

    &__play-button {
        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        z-index: 10;
        width: 60px;
        height: 60px;
        border: 2px solid $white;
        border-radius: 50%;
        background-color: unset;
        transition: .3s $timing-function;
        cursor: pointer;

        &--hidden {
            opacity: 0;
            z-index: -1;
        }

        &:hover {
            opacity: .8;
        }

        &-icon {
            color: $white;
        }
    }

    &__left,
    &__right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: calc(50% - 55px);
    }

    .button {
        margin-top: 275px;
    }
}