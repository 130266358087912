.logo {
  &-wrapper {
    cursor: pointer;
    height: auto;
  }
}

@media (min-width: 991.98px) {
  .logo {
    &-wrapper {
      width: clamp(100px, 10vw, 150px);
    }
  }
}
