.document {
  background-color: $black;
  overflow-x: hidden;

  .header {
    align-items: flex-start;
    position: static;
    padding-top: 35px;
    padding-bottom: 35px;

    &__button {
      text-decoration: none;
      height: 54px;
    }

    &__actions {
      display: flex;
      align-items: center;

      .navigation__list {
        display: none;
      }
    }
  }

  &__section {
    display: flex;
    justify-content: space-between;

    &-right,
    &-left {
      width: 50%;
    }

    &-left {
      width: 50%;
      margin-right: 30px;
    }
  }

  &__content {
    color: $white;
    padding-bottom: 80px;
    margin-top: 150px;

    .link {
      margin: 0 10px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 1.5;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__link {
    font-size: 16px;
  }

  &__title {
    font-weight: bold;
    line-height: 1.5;

    &--primary {
      font-size: clamp(25px, 1.75rem, 40px);
    }

    &--secondary {
      font-size: clamp(20px, 1.25rem, 30px);
      margin: 30px 0;
    }

    &--tertiary {
      font-size: clamp(18px, 0.85rem, 20px);
      margin: 10px 0;
    }
  }

  &--imprint {
    .link {
      margin-left: 0;
    }
  }
}