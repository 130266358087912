.movies {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: clamp(10px, 5vh, 50px) auto 0;

  .cards--container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}

@media (min-width: 768px) {
  .movies {
    .cards--container {
      flex-direction: row;
      justify-content: center;

      > * {
        flex: 0 0 clamp(240px, 50%, 460px);
      }
    }
  }
}
