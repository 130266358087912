.drop {
    display: flex;
    flex-direction: column;

    &__how-to-mint {
        margin-top: 285px;
        margin-bottom: 190px;
    }

    &__hero {}

    &__drop-details {}

    &__last-minted {}

    &__mint-progress {}

    &__owning-info {}
}