.advisory-board {
  margin-top: clamp(80px, 10vw, 160px);

  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__heading {
    line-height: 1.3;
    font-weight: 300;
    text-align: center;
    margin-bottom: 82px;
  }
}
