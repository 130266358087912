.partner {
  &s {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: clamp(10px, 5vh, 50px) auto 0;

    &__title {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 70px;

      &::after {
        content: '';
        height: 125px;
        width: 1px;
        background-color: $yellow;
        margin-top: clamp(40px, 1vw, 150px);
      }
    }

    &__logos {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__link {
    display: inline-flex;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 0.3s $timing-function;

    &:hover {
      opacity: 0.6;
    }
  }

  &__logo {
    height: 100%;
    object-fit: contain;

    &-wrapper {
      height: 150px;
      padding: 1rem;

      &--beta {
        height: 100px;
      }
    }
  }
}
@media (min-width: 575.98px) {
  .partners__logos {
    flex-direction: row;
  }
}
