.overview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 125px;

  &__title {
    margin-bottom: 30px;
  }

  &__preload {
    height: calc(100vh - 100px);
    background-color: $white;
  }

  &__not-revealed {
    margin: 10rem 0;
    font-size: 26px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-wrapper {
    color: $black-dark;
    background-color: $white;
    padding: 60px 0 130px;
    transition: 0.3s $timing-function;

    &--videoPlaying {
      background: $black;
      color: $black;

      & * {
        transition: 0.3s ease-in-out;
        opacity: 0;
      }

      .overview__content {
        background-color: $black;
      }

      .overview {
        &,
        &__content,
        &__video-wrapper * {
          opacity: 1;
        }
      }

      .overview__video-wrapper {
        transition: none;
        border-color: $black-dark;
      }
    }
  }

  &__nft-video {
    width: 100%;
    max-width: 900px;
    object-fit: cover;
  }

  &__video {
    aspect-ratio: 16 / 9;
    max-width: 900px;
    object-fit: cover;

    &-wrapper {
      top: 0;
      left: 0;
      transition: 0.3s ease-in-out, border-color 0s ease-in-out;
      border: clamp(20px, 5vw, 40px) solid transparent;
    }

    &-close {
      border: none;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -80px;
      right: -80px;
      z-index: 100;
      cursor: pointer;
      background-color: unset;
      transition: 0.3s $timing-function;

      &-icon {
        color: $white;
        width: 30px;
        height: 30px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__link {
    text-decoration: none;
    background-color: $black-dark;
    color: $white;
    margin-top: 120px;
    cursor: pointer;
  }

  &__content {
    position: relative;
    transition: 0.3s $timing-function;
    max-width: 900px;
    width: 100%;
  }

  &__properties {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    max-width: 900px;
    width: 100%;

    &-title {
      font-size: 30px;
      margin-bottom: 25px;
      font-weight: 300;
    }
  }

  &__tags {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 15px;
    max-height: 400px;
    overflow-y: scroll;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $black-dark;
      border-radius: 5px;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;

    color: $black-dark;
    line-height: 1;
    margin-top: 10px;

    &-block {
      display: flex;
    }

    &-desc {
      display: flex;
      flex-direction: column;
    }

    &-buttons {
      display: flex;
    }

    &-text {
      font-size: 16px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .address {
        display: inline-flex;
        text-decoration: underline;
        margin-left: 5px;
        font-weight: bold;
        color: $black;
        transition: 0.3s $timing-function;

        &:hover {
          color: $yellow;
        }
      }
    }

    &-button {
      display: flex;
      justify-content: center;
      align-items: center;

      background-color: unset;
      border-radius: 50%;
      border: 1px solid $black-dark;
      width: 38px;
      height: 38px;
      cursor: pointer;
      color: $black;

      transition: 0.3s $timing-function;

      &:hover {
        opacity: 0.8;
        border-color: $yellow;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }

      &-icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__tag {
    border: 1px solid $black-dark;

    &-label {
      color: $black-dark;
    }

    &-value {
      color: $black-dark;
    }
  }

  &__icon {
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    max-height: 30px;
    margin-right: 5px;
  }

  &__button {
    display: inline-flex;
    align-items: center;
    border: 1px solid $black-dark;
    transition: 0.3s $timing-function;
    text-decoration: none;
    color: $black-dark;

    &s {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &:hover {
      opacity: 0.5;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__image {
    &-wrapper {
      max-width: 900px;
      top: 0;
      left: 0;
      transition: 0.3s ease-in-out;
    }
  }
}
