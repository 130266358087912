:root {
    --minted-progress: 25%;
}

.mint-progress {
    margin-top: 80px;

    &__title {
        margin-bottom: 85px;
    }

    &__subtitle {
        margin-bottom: 40px;
    }

    &__content {
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 80px;
        }
    }

    &__image {
        &-wrapper {
            display: flex;
        }

        width: 200px;
    }

    &__current-point {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        bottom: 100%;
        left: var(--minted-progress);
        text-align: center;

        &::after {
            content: "";
            height: 60px;
            width: 1px;
            background-color: $yellow;
            margin-top: 10px;
        }
    }

    &__unavailable {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: var(--minted-progress);
        background-color: rgba($grey, .8);
        z-index: 10;

        &::before {
            position: absolute;
            content: "";
            background-color: $bg-color;
            top: 5px;
            height: 5px;
            width: 100%;
            right: 0;
            left: 0;
        }
    }

    &__progress-bar {
        display: flex;
        flex-direction: column;
        position: relative;

        &::before {
            content: "";
            background-color: $yellow;
            margin-bottom: 5px;
            height: 5px;
        }
    }

    &__text {
        font-size: 20px;

        &--disabled {
            color: $grey;
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 200px;

        .title {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            &::after {
                content: "";
                height: 125px;
                width: 1px;
                background-color: $yellow;
                margin-bottom: 60px;
                margin-top: 110px;
            }
        }
    }

    .checkpoint {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 25%;
        margin-top: 20px;
        left: 20px;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 3;
        }

        &::before {
            position: absolute;
            top: -55px;
            z-index: 10;
            content: "";
            background: url(/assets/images/icons/star.svg) no-repeat center;
            width: 40px;
            height: 40px;
            -webkit-filter: grayscale(100%);
            -webkit-filter: grayscale(1);
            filter: grayscale(100%);
            opacity: .6;
        }

        &--active {
            .checkpoint {
                &__number,
                &__label {
                    color: $yellow;
                }

                &__number,
                &__label,
                &__image,
                &__text {
                    opacity: 1;
                }

                &__image {
                    filter: none;
                }
            }

            &.checkpoint {

                &::before,
                &::after {
                    opacity: 1;
                    filter: none;
                }
            }
        }

        &__number,
        &__label {
            opacity: .3;
        }

        &__number {
            font-size: 14px;
        }

        &__label {
            font-size: 16px;
            margin-top: 5px;
        }

        &__image {
            width: 50%;
            -webkit-filter: grayscale(100%);
            -webkit-filter: grayscale(1);
            filter: grayscale(100%);
            opacity: .3;

            &-wrapper {
                display: flex;
                justify-content: flex-end;
                margin-top: 30px;
            }
        }

        &__text {
            line-height: 1.5;
            font-size: 16px;
            margin-top: 20px;
            max-width: 220px;
            text-align: right;
            opacity: .3;
        }
    }

    &__checkpoints {
        display: flex;
        justify-content: space-between;

        & > .title {
            display: none;
        }
    }

    &__content {}
}