@media (max-width: 1699.98px) {
  .container {
    max-width: 1400px;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
  }

  .arrow {
    position: relative;
    left: 30px;
  }

  .tags {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 1399.98px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
  }

  .movie-wrapper {
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    max-width: 100%;
  }

  .header,
  .collection {
    &.container {
      max-width: unset;
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

@media (max-width: 1199.98px) {
  .idea {
    margin-top: 100px;

    &__title {
      margin-bottom: 30px;
    }

    &__content {
      flex-direction: column;
      align-items: flex-start;
    }

    &__right {
      width: 100%;
      padding-top: 60px;
      margin-left: 0;
    }

    &__image {
      &--bg {
        .react-parallax-bgimage {
          top: -40px;
        }
      }
    }
  }

  .award-reveal {
    &__description {
      font-size: 14px;
    }
  }

  .tags {
    grid-template-columns: repeat(4, 1fr);
  }

  .award-reveal {
    &.container {
      max-width: unset;
    }
  }

  // .container {
  //   max-width: 964px;
  // }

  .collection {
    &.container {
      max-width: unset;
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }

    &__movieshots {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .movie {
    &-wrapper {
      max-width: 100%;
    }
  }

  .movie {
    &__cover-wrapper {
      margin-right: 20px;
    }
  }

  .arrow {
    display: none;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .drop-details {
    &__button {
      white-space: nowrap;
    }

    &__table {
      grid-template-columns: repeat(1, 1fr);
      max-height: unset;

      &-row {
        &:nth-child(even) {
          background-color: rgba($grey, .1);
        }

        &:last-child {
          border-bottom: unset;
        }
      }

      &-cell {
        min-height: unset;
        max-height: unset;
        padding: 10px;
        line-height: 1.5;
      }
    }
  }

  .instructions {
    flex-wrap: wrap;
    justify-content: space-between;

    &-wrapper {
      margin-top: 100px;
    }

    &__block {
      width: calc(50% - 30px);
      min-height: unset;

      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }

  .how-to-mint,
  .learn-more {
    flex-direction: column;
    margin-top: 100px;

    >.title {
      width: 100%;
    }

    &__title {
      margin-bottom: 30px;

      br {
        display: none;
      }
    }

    &__left,
    &__right {
      width: 100%;
    }

    &__right {
      margin-top: 40px;
      width: 100%;
    }


  }

  .learn-more {
    margin-top: 0;

    &__content {
      flex-direction: column;
    }
  }

  .hero {
    &__title {
      margin-bottom: 30px;
    }

    &__content {
      flex-direction: column;
    }

    &--free-mint {
      .hero {

        &__left,
        &__right {
          width: 100%;
          margin: 0;
        }
      }

      & .idea__right {
        padding: 0;
      }
    }

    &__left {
      width: 100%;
    }

    &__right {
      width: 100%;
      padding-top: 60px;
      margin-left: 0;
    }

    &__image {
      &--bg {
        .react-parallax-bgimage {
          top: -40px;
        }
      }
    }
  }

  .overview {
    &-wrapper {
      padding-bottom: 40px;
    }

    &__link {
      margin-top: 40px;
    }

    &__video {
      &-close {
        right: -35px;
        top: -35px;
      }
    }

    &__properties {
      margin-top: 20px;
    }

    &__info {
      &-block {
        margin-bottom: 20px;
      }

      &-text:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .tags {
    grid-template-columns: repeat(4, 1fr);
  }

  .award-reveal {
    &__description {
      font-size: 16px;
    }

    &__video-close {
      right: -35px;
      top: -35px;
    }

    &__content {
      flex-direction: column-reverse;
    }

    &__left,
    &__right {
      width: 100%;
    }

    &__image {
      height: 50vw;
    }

    &__top {
      margin-bottom: 30px;
    }

    &__footer {
      display: flex;
      justify-content: center;
      align-self: center;
      margin-top: 70px;
      width: 100%;
    }

    &__right {
      margin-bottom: 30px;
    }
  }

  .document {
    .header {
      padding: 30px;
    }

    & &__content {
      padding-bottom: 80px;
    }

    &__section {
      flex-direction: column-reverse;

      &-left {
        width: 100%;
      }

      &-right {
        display: none;
      }
    }
  }

  .movie {
    &__text {
      font-weight: 300;
      margin-bottom: 10px;
    }

    &__number {
      margin-right: 40px;
    }

    &__description-wrapper {
      flex-direction: column;
    }

    &__cover-wrapper {
      max-width: 300px;
      max-height: 300px;
    }

    &__garanty {
      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &::before {
        display: inline-flex;
        content: "//";
        margin-right: 5px;
      }

      &:not(:last-child) {
        &::after {
          display: none;
        }
      }
    }

    &__garanties {
      margin-top: 30px;
      flex-direction: column;
    }

    &__top {
      flex-direction: column;
      align-items: center;
    }

    &__bottom {
      position: static;
      transform: none;
      width: 100%;
      margin-top: 40px;
    }
  }

  .partners {
    margin: 80px auto 0;
  }

  .project-description {
    &__section {
      margin-top: 70px;

      &--owning,
      &--screentime {
        .title {
          margin-bottom: 50px;
        }

        .project-description__text {
          position: static;
          max-width: 100%;
        }

        strong {
          margin-left: 0;
        }
      }

      &--collect {
        .project-description__section {
          &-content {
            flex-direction: column-reverse;
          }

          &-left {
            margin-top: 30px;
            margin-bottom: 0;
            top: 0;
          }
        }
      }

      &-content {
        flex-direction: column;
      }

      &-left,
      &-right {
        width: 100%;
      }

      &-left {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
  }

  .movie-shots {
    margin-top: 100px;
  }

  .movie-info {

    &__left,
    &__right {
      width: 100%;
    }

    &__left {
      margin-right: 0;
    }

    &__right {
      margin-bottom: 50px;
    }

    &__section {
      flex-direction: column-reverse;
    }

    &__description {
      margin-bottom: 30px;
    }
  }

  .faq {
    &__title {
      margin-bottom: 50px;
    }

    &__content {
      flex-direction: column-reverse;
    }

    &__left,
    &__right {
      width: 100%;
    }

    &__left {
      margin-right: 0;
    }

    &__right {
      margin-bottom: 30px;
    }
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .document {
    .header {
      &__actions {
        flex-direction: column;
        align-items: flex-end;
      }

      &__button {
        text-decoration: none;
        margin-right: 0;
        margin-top: 10px;
      }

      &__wallet-address {
        margin-top: 10px;
      }
    }

    &__content {  
      margin-top: 205px;
    }
  }

  .drop-details {
    &__footer {
      margin-top: 50px;
      align-items: flex-start;
      flex-direction: column;
    }

    &__buttons {
      margin-top: 20px;
      margin-left: 0;
    }
  }

  .mint-progress {
    >.title {
      display: none;
    }

    &__unavailable {
      top: var(--minted-progress);
      left: 0;
      max-width: 24px;

      &::before {
        position: absolute;
        content: "";
        background-color: $black-dark;
        top: 0;
        height: 100%;
        width: 3px;
        right: 0;
        left: 19px;
      }
    }

    &__checkpoints {
      flex-direction: column;
      margin-left: 40px;

      &>.title {
        display: inline-flex;
        flex-direction: column;
      }
    }

    &__text {
      writing-mode: vertical-rl;
      transform: rotateZ(180deg);
    }

    &__current-point,
    &__cinema-strip {
      display: none;
    }

    &__content {
      display: flex;

      &-header {
        flex-direction: column;

        .mint-progress__text:first-child {
          color: $yellow;
        }
      }
    }

    &__progress-bar {
      width: 100%;
      margin-left: 20px;

      &::before {
        height: 100%;
        width: 2px;
        position: absolute;
        left: 22px;
        top: 0;
        bottom: 0;
        z-index: 10;
      }

      &::after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        max-width: 20px;
        min-width: 20px;
        background: url('/assets/images/icons/vertical-strip.svg') repeat center center;
        background-color: $white;
        z-index: 9;
      }
    }

    .checkpoint {
      width: 100%;
      align-items: flex-start;
      margin-top: 100px;

      &::before {
        left: calc(-12% - 14px);
        top: unset;
        height: 30px;
        width: 30px;
        background-size: contain;
      }

      &__text {
        text-align: left;
      }

      &__image {
        width: 50%;

        &-wrapper {
          justify-content: flex-start;
        }
      }
    }
  }

  .hero {
    &__guarantees {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 20px;
    }

    &__guarantee {
      display: flex;

      &:not(:last-child) {
        margin-left: 0;
        margin-bottom: 10px;
      }

      &::before {
        display: block;
        content: "//";
        margin-right: 10px;
      }

      &::after {
        display: none;
      }
    }
  }

  .instructions {
    flex-direction: column;

    &-wrapper {
      margin-top: 100px;
    }

    &__block {
      width: 100%;
      min-height: unset;

      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
  }

  .idea__content {
    flex-direction: column;
  }

  .overview {
    &__info {
      display: flex;
      flex-direction: column;
    }
  }

  .tags {
    grid-template-columns: repeat(4, 1fr);
  }

  .award-reveal {
    &__description {
      font-size: 14px;
    }
  }

  .movie-info {
    margin-top: 80px;
  }

  .collection {
    &__movieshots {
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
    }
  }

  .footer {
    flex-direction: column;
    align-items: center;

    .decor {
      bottom: unset;
    }

    &__logo {
      margin-bottom: 50px;
    }

    &__links {
      &:not(.footer__links--socials) {
        flex-direction: column;
        align-items: center;

        .footer__link-wrapper {
          margin-right: 0;

          &:not(:last-child) {
            margin-bottom: 15px;

            &::after {
              display: none;
            }
          }
        }
      }

      &--socials {
        margin-right: 0;
      }
    }

    &__section {
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 50px;
      }
    }
  }

  .project-description {
    &__title {
      margin-bottom: 50px;

      br {
        display: none;
      }
    }

    &__links {
      margin-top: 20px;
    }

    &__link-wrapper {
      width: 40px;

      &:not(:last-child) {
        margin-right: 30px;
      }
    }

    &__grid {
      grid-template-columns: repeat(2, 1fr);

      &-element {
        padding: 25px 0;
      }
    }

    .movie-shot {
      &__image-wrapper {
        margin-bottom: 0;
      }

      &__info-wrapper {
        display: none;
      }
    }
  }

  .hero__content {
    flex-direction: column;
  }

  .team__members {
    grid-template-columns: repeat(2, minmax(130px, 360px));
  }

  .movie {
    &__top {
      flex-direction: column;
      align-items: center;

      &::before {
        position: static;
        transform: none;
        writing-mode: initial;
        margin-right: 0;
        margin-bottom: 30px;
      }
    }

    &__bottom {
      position: static;
      transform: none;
      width: 100%;
      margin-top: 40px;
    }

    &__number {
      margin-bottom: 30px;
      white-space: nowrap;
    }

    &-parallax {
      width: calc(100% + 60px);
      margin: 0 -30px;
    }

    &-parallax {
      .react-parallax-bgimage {
        object-fit: cover;
        top: -50px;
      }
    }

    &-wrapper {
      margin: 0 auto;
    }

    &__cover-wrapper {
      margin-right: 0;
    }

    &__content {
      flex-direction: column;
      align-items: center;
    }

    &__description {
      &-wrapper {
        position: relative;
        margin-top: 10px;
      }

      &-icon {
        // position: absolute;
        // right: 0;
        // top: 0;
        // transform: translate3d(-10px, -160%, 0);
      }
    }

    &__text {
      font-weight: 300;
      // text-align: center;
      margin-bottom: 10px;
    }

    &__title {
      // text-align: center;
    }
  }

  .advisory-board {
    padding-top: 0;
  }

  .team-wrapper {
    margin: 100px auto;
  }

  .roadmap {
    margin-top: 80px;

    &__line {
      right: 100%;
    }

    &__point {
      text-align: left;
      padding-left: 80px;
      padding-right: 0;
      width: 100%;

      &--active {
        &::after {
          height: 50%;
        }
      }

      &:nth-child(even) {
        align-self: flex-start;
      }

      &:not(:last-child) {
        padding-bottom: 50px;
      }

      &::before {
        right: unset;
        left: 0;
        transform: translate3d(-7.5px, 0, 0);
      }
    }

    &__content {
      align-items: flex-start;
      padding-top: 50px;
    }
  }

  .movie-shot {
    &__image {
      &-wrapper {
        border-width: 10px !important;
      }
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .owning-info {
    &__awards {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .drop-details {
    &__buttons {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    &__button {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }

  .mint-progress {
    .checkpoint::before {
      left: calc(-11% - 15px);
    }
  }

  .idea {
    &__title {
      white-space: unset;

      br {
        display: none;
      }
    }
  }

  .mint-modal {
    height: auto;
    max-height: unset;
    min-height: 500px;
    border-radius: 0;

    &--approve {
      .mint-modal__content {
        margin-top: 20px;
      }
    }

    &__link {
      display: inline-flex;
      margin-left: 0;
      margin-top: 8px;
    }

    &__input {
      &-text {
        margin-top: 10px;
      }

      &-content {
        align-items: flex-start;
        flex-direction: column;
      }
    }
  }

  .overview {
    &__title {
      display: flex;
      flex-direction: column;
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      white-space: nowrap;
      padding: 12px 15px;
    }

    .address {
      margin-left: 0;
      margin-top: 5px;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
    }
  }

  .award-reveal {
    &__owner {
      display: inline-flex;
      flex-direction: column;
    }

    &__button {
      width: 100%;
      padding: 12px 15px;
      white-space: nowrap;
    }

    &__address {
      margin-left: 0;
      margin-top: 5px;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
      border-bottom: 1px solid;
    }

    &__description {
      font-size: 16px;

      &:not(:last-child) {
        margin-left: 0px;
        margin-bottom: 10px;
      }

      &::after {
        display: none;
      }

      &-wrapper {
        flex-direction: column;
        margin-bottom: 30px;
      }
    }
  }

  .tags {
    grid-template-columns: repeat(2, 1fr);
  }

  .collection {
    &.container {
      padding-left: 15px;
      padding-right: 15px;
    }

    &__header {
      flex-direction: column;
      align-items: center;

      &-info {
        &-wrapper {
          margin-top: 20px;
        }

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }

  .movie-shots__title {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
  }

  .hero {
    &__title {
      white-space: unset;

      br {
        display: none;
      }
    }
  }
}

@media (max-width: 400px) {
  .document {
    .header {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__navigation {
        display: none;
      }

      &__actions {
        align-items: center;
      }

      &__logo-wrapper {
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 350px) {
  .award-reveal {
    &__owner {
      width: 100%;
    }
  }

  .tags {
    grid-template-columns: repeat(2, 1fr);
  }

  .container {
    max-width: 290px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .header {
    padding-left: 0;
    padding-right: 0;

    &.header--burgerOpen {
      padding: 35px 15px 100px;

      .header {
        &__burger--open {
          right: 15px;
        }
      }
    }
  }
}