.index {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 3rem;
  margin: clamp(10px, 5vh, 50px) auto 0;

  h2,
  h3 {
    margin-bottom: 0.5em;
  }

  p {
    margin-bottom: 1.5rem;
    line-height: 1.5;
  }

  section {
    margin-top: 2rem;
  }

  .bg-header {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 4.25rem;
    color: $black-1;
    font-weight: bold;
    text-shadow: -1px -1px 0 $yellow, 1px -1px 0 $yellow, -1px 1px 0 $yellow,
      1px 1px 0 $yellow;
    opacity: 0.2;
    z-index: -1;
  }

  .teaser-text {
    line-height: 1.5;
    font-size: 0.875em;
  }

  .button {
    display: inline-block;

    &-text {
      display: inline-flex;
      align-items: end;
      justify-content: center;
    }

    &-img{
      margin-left: 0.5rem;
      height: 1rem;
    }
  }

  .questions {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    gap: 3rem;
    padding-top: 4rem;

    section {
      text-align: center;
    }

    .more-questions {
      flex: 1 0 100%;
      text-align: center;
    }
  }
}

.landing-bg-element {
  position: fixed;
  filter: grayscale(0.8);
  width: 100%;
  z-index: -2;
  opacity: 0.5;
  top: 0;
}

.w-70 {
  width: 70%;
}

.container--half {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 2rem;
  padding: 2rem 0;
}

.container--full {
  height: calc(100vh - 120px);
  width: 100%;
}

@media (max-width: 767.98px) {
  .container--half {
    flex-direction: column-reverse;
  }

  .w-70 {
    width: 100%;
  }

  .container--full {
    height: inherit;
    width: 100%;
  }
}

@media (min-width: 991.98px) {
  .index {
    .questions {
      flex-direction: row;
      margin-top: -3rem;
      padding-top: 9.5rem;

      > * {
        flex: 0 0 calc(50% - 2rem);
      }
    }

    .bg-header {
      font-size: 10rem;
    }
  }

  .container--half {
    align-items: center;
    justify-content: space-between;

    & > * {
      flex: 0 0 calc(40% - 2rem);
    }
  }
}
