:root {
  --background-color: #ffffff;
  --text-color: #1c1c1c;
  --border-color: #e0b472;
  --accent-background: #e0b472;
  --accent-color: #1c1c1c;
  --accent-color-hover: #e0b472;
  --secondary-text-color: #ffffff;
  --onboard-connect-sidebar-background: var(--accent-background);
  --onboard-close-button-background: var(--accent-background);
  --onboard-connect-sidebar-color: var(--text-color);
  --onboard-connect-sidebar-progress-background: var(--secondary-text-color);
  --onboard-connect-sidebar-progress-color: var(--accent-color);
  --onboard-connect-header-background: var(--background-color);
  --onboard-connect-header-color: var(--text-color);
  --onboard-main-scroll-container-background: var(--background-color);
  --onboard-link-color: var(--accent-color);
  --onboard-wallet-button-background: var(--background-color);
  --onboard-wallet-button-background-hover: var(--accent-color-hover);
  --onboard-wallet-button-border-color: var(--border-color);
  --onboard-wallet-app-icon-border-color: var(--border-color);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 35px;
  padding-bottom: 35px;
  transition: 0.3s $timing-function;

  &__wallet-address {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 18px;
    line-height: 1;
    font-weight: bold;
    text-transform: uppercase;
    background-color: $yellow;
    color: $black-dark;
    height: 54px;

    .address {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__burger {
    background-color: unset;
    border: none;
    cursor: pointer;

    transition: all 0.3s $timing-function;

    &:hover {
      opacity: 0.7;
    }

    width: 30px;
    height: 30px;

    &-line {
      height: 1px;
      width: 100%;
      background-color: $white;
      transform-origin: 0 0;
      transition: all 0.3s $timing-function;
    }

    &-icon {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      width: 30px;
      height: 30px;
    }

    &--open {
      .header__burger-line {
        &:nth-child(1) {
          transform: rotateZ(45deg);
          width: 40px;
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotateZ(-45deg);
          width: 40px;
        }
      }
    }
  }

  &-wrapper {
    background-color: $bg-color;
    transition: 0.3s $timing-function;
    position: relative;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;

    &.hidden {
      opacity: 0;
      transform: translateY(-120px);
      transition: 0.3s $timing-function;
    }

    &.visible {
      opacity: 1;
      transform: none;
      transition: 0.3s $timing-function;
    }
  }

  &.header--burgerOpen {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 35px 30px 100px;

    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: $black-1;

    &::before,
    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -2;

      background-repeat: no-repeat;
      background-position: 70% 25%;
    }

    &::before {
      z-index: -1;
      filter: blur(2rem);
    }

    .header {
      &__burger {
        position: absolute;
        top: 50px;
        left: 30px;
      }

      &__logo {
        &-wrapper {
          align-self: flex-start;
        }
      }

      &__actions {
        flex-direction: column;
      }
    }
  }

  &__social {
    &-link {
      &-wrapper {
        &:not(:last-child) {
          margin-right: 16px;
        }
      }

      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;

        svg {
          width: 100%;

          path {
            transition: fill 0.3s $timing-function;
          }
        }

        &:hover {
          svg {
            path {
              fill: $yellow;
            }
          }
        }
      }
    }

    &-links {
      display: flex;
      align-items: center;
      margin-right: 30px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__navigation {
    margin-right: 45px;
  }

  &__button {
    display: flex;
    align-items: center;
    white-space: nowrap;

    span {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-icon {
      width: 30px;
      height: 30px;

      margin-right: 10px;
    }
  }

  .navigation {
    &__list {
      display: flex;
      align-items: center;
      list-style: none;
      margin: 0;
      padding-left: 30px;

      &-item {
        &:not(:last-child) {
          margin-right: clamp(20px, 2vw, 45px);
        }
      }

      &-button {
        background: unset;
        border: none;
        font-size: 0.83rem;
        text-transform: uppercase;
      }
    }

    &--mobile {
      transition: opacity 0.3s $timing-function;
      display: flex;
      flex-direction: column;
      margin-right: 0;

      .header__wallet-address {
        margin-top: 20px;
      }

      .navigation {
        width: 100%;

        &__list {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: stretch;
          padding-left: 0;
          margin-bottom: 20px;

          &-item {
            margin-right: 0;
            text-align: center;

            &:not(:last-child) {
              margin-bottom: 30px;
            }
          }

          &-button {
            text-transform: uppercase;
            font-size: 30px;
            width: 100%;
          }
        }
      }

      .header__button {
        justify-content: center;
        font-size: 16px;
        margin-top: 20px;

        &:not(:last-child) {
          margin-right: 0;
        }
      }
    }
  }

  &-mobile-menu {
    list-style-type: none;
    color: $black;
    background-color: $black-1;

    li {
      margin: 0;
      color: $black;
      background-color: $black-1;
    }

    li > button {
      width: 100%;
      height: 100%;
      text-align: center;
      background-color: #262626;
      color: $white;
      border: none;
      padding: 1rem 0;
      margin: 1px 0;
      font-size: 16px;
      cursor: pointer;
    }

    li > button:hover {
      background-color: #333333;
    }
  }
}
