.hero {
    display: flex;
    flex-direction: column;
    position: relative;

    &--free-mint {
        margin-bottom: clamp(60px, 10vw, 120px);

        .hero {
            &__left {
                width: 50%;
            }

            &__right {
                width: calc(50% - 60px);
            }
        }
    }

    .idea {
        margin-top: 0;
        padding: 0;
        height: 100%;

        &__right {
            padding-bottom: 15vh;
        }
    }

    & .arrow-wrapper {
        left: -30px;
        bottom: 0;
    }

    & .animated-container {
        display: inline-flex !important;
    }

    &__label {
        font-size: 28px;
        line-height: 2;
        margin-bottom: 15px;
    }

    &__description {
        opacity: .5;
        font-size: 16px;

        &:first-child {
            font-weight: bold;
        }

        &:not(:last-child) {
            &::after {
                content: "|";
                margin: 0 10px;
            }
        }

        &-wrapper {
            display: flex;
            margin-bottom: 60px;
            margin-top: 10px;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
    }

    &__image {
        &-wrapper {
            position: relative;
        }
    }

    &__tags {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 15px;
        max-height: 240;
        overflow-y: auto;
        padding-right: 10px;

        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
            background-color: $grey;
            border-radius: 5px;
        }
    }

    &__tag, &__quote {
        border: 1px solid $white;
        background-color: $bg-color;

        &-label {
            color: $yellow;
        }

        &-value {
            color: $white;
        }
    }

    &__left,
    &__right {
        display: flex;
        flex-direction: column;
        width: calc(50% - 60px);
    }

    &__right {
        align-items: flex-start;

        .movie-link {
            display: inline-flex;
            padding: 0 0.5rem;
        }

        .icon {
            height: 1.5rem;
            width: auto;
            min-width: 1.5rem;
        }
    }

    &__title {
        margin-bottom: 70px;
    }

    &__subtitle {
        margin-bottom: 30px;
    }

    &__info-list {
        list-style: initial;

        &-item {
            font-size: clamp(18px, 2vw, 26px);
            margin-left: 25px;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

    &__guarantees {
        display: flex;
        align-items: center;
        margin-top: 80px;
    }

    &__guarantee {
        text-transform: uppercase;
        font-size: clamp(12px, 2vw, 14px);
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: 2px;

        &:not(:last-child) {
            &::after {
                content: "//";
                margin: 0 10px;
            }
        }
    }

    &__call-to-action {
        &-wrapper {
            display: flex;
            align-items: center;
            margin-top: 40px;
        }

        &-text {
            margin-bottom: 20px;
            font-size: clamp(35px, 5vw, 55px);

            &--one-line {
                &,
                & strong {
                    line-height: 1;
                    margin-bottom: 0;
                }
            }
        }

        &-image {
            position: relative;
            left: 0;
            top: 10px;
            z-index: -1;
            animation: pulse 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
        }

        &-button {
            border: 1px solid $yellow;
            padding: 25px;
            background-color: unset;
            color: $white;
            text-transform: uppercase;
            font-size: clamp(16px, 2vw, 21px);
            margin-top: 20px;
            transition: .3s $timing-function;
            cursor: pointer;
            max-height: unset;

            &:hover {
                background: $yellow;
            }

            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }

    &__buttons {
    }
}