.leaderboard-table {
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  position: relative;
  border-style: hidden;

  thead tr th {
    text-align: center;
    vertical-align: middle;
    font-weight: 500;
    background-color: transparent;
    color: #7b7b7b;
  }

  tbody tr:nth-child(even) {
    background-color: rgba(44, 44, 44, 0.6);
  }

  tbody tr.show-trophie:first-child {
    background-color: #5a4d32;
    td:first-child {
      font-weight: bold;
      font-size: 26px;
    }
  }

  tbody tr.show-trophie:nth-child(2) {
    background-color: #626262;
    td:first-child {
      font-weight: bold;
      font-size: 24px;
    }
  }

  tbody tr.show-trophie:nth-child(3) {
    background-color: #594a3d;
    td:first-child {
      font-weight: bold;
      font-size: 22px;
    }
  }

  th,
  td {
    text-align: center;
    vertical-align: middle;
    padding: 8px;
    overflow-wrap: break-word;
    border: 1px solid rgb(91, 91, 91);
  }
}

.leaderboard-address {
  display: flex;
  margin-left: 0.5rem;
}

.leaderboard-link {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  width: 100%;

  &:hover {
    text-decoration: underline;
    color: $yellow;
  }
}

.leaderboard__trophy {
  &--gold {
    height: 1.8rem;
  }
  &--silver {
    height: 1.6rem;
    margin-left: 0.1rem;
  }
  &--bronz {
    height: 1.4rem;
    margin-left: 0.2rem;
  }
}

.leaderboard__button-group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.leaderboard-btn-active {
  border-color: $yellow;
}

.leaderboard_mb {
  margin-bottom: 0.5rem;
}

.leaderboard__small {
  font-size: 14px;
  margin-bottom: 2rem;
  text-align: center;
}

.pagination-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

@media (max-width: 767.98px) {
  .leaderboard-table {
    width: 100%;
  }
}
