.roadmap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: clamp(120px, 10vw, 260px);

  &__title {
    margin-bottom: 150px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 125px;
    width: 100%;
  }

  &__line {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 50%;
    transform: translate3d(50%, 0, 0);
    width: 2px;
    height: 100%;
    background-color: rgba($white, 0.3);
  }

  &__point {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    text-align: right;
    width: 50%;
    padding-right: 80px;
    position: relative;
    min-height: 125px;

    &:not(:last-child) {
      padding-bottom: 25px;
    }

    &::before {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: $bg-color;
      border: 2px solid rgba($white, 0.3);
      transform: translate3d(7.5px, 0, 0);
    }

    &::after {
      display: none;
      content: "";
      position: absolute;
      right: -1px;
      top: 0;
      z-index: 1;
      height: 100%;
      background-color: $yellow;
      width: 2px;
      transform: translate3d(0, -100%, 0);
    }

    &:nth-child(even) {
      align-self: flex-end;
      text-align: left;
      padding-right: 0;
      padding-left: 80px;

      &::before {
        right: unset;
        left: 0;
        transform: translate3d(-7.5px, 0, 0);
      }

      &::after {
        left: -1px;
        right: unset;
      }
    }

    &--active {
      opacity: 1;

      .roadmap__point {
        &-title,
        &-text {
          opacity: 1;
        }
      }

      &::after {
        display: block;
      }

      &::before {
        background-color: $yellow;
        border-color: $yellow;
      }
    }

    &-title {
      text-transform: uppercase;
      color: $yellow;
      margin-bottom: 10px;
      opacity: 0.25;
    }

    &-text {
      opacity: 0.25;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
}
