@font-face {
  font-family: "Metric";
  src: url("/assets/fonts/metric/Metric-Light.woff2") format("woff2"),
    url("/assets/fonts/metric/Metric-Light.woff") format("woff");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Metric";
  src: url("/assets/fonts/metric/Metric-Bold.woff2") format("woff2"),
    url("/assets/fonts/metric/Metric-Bold.woff") format("woff");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue World";
  src: url("/assets/fonts/helvetica/Helvetica_Neue_World_Light.otf") format("woff2");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue World";
  src: url("/assets/fonts/helvetica/Helvetica_Neue_World_Bold.otf") format("woff2");
  font-weight: bold;
  font-display: swap;
}