.newsletter-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $yellow;
  padding: 0.65rem;

  &__button {
    cursor: pointer;
    border: 2px solid transparent;
    font-size: 0.83rem;
    font-weight: 500;
    padding: 8px 22px;
    text-transform: uppercase;
    background: unset;
    color: #111;
    border-color: #111;
    position: relative;
    font-family: Poppins, Arial, Helvetica, sans-serif;
    text-align: center;
    text-decoration: none;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    max-height: 54px;

    &:hover {
      color: white;
      background-color: #111;
      opacity: 0.9;
    }
  }
}
