.toaster {
    font-family: "Helvetica Neue World", Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: $black;

    .Toastify__progress-bar {
        background-color: $yellow;
    }

    .Toastify__toast-icon {
        svg {
            fill: $yellow;
        }
    }
}