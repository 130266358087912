$min_width: 320px;
$max_width: 2560px;
$min_font: 14px;
$max_font: 24px;

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --hero-image-height: 300px;
  --hero-image-width: 300px;
}

html {
  font-display: swap;
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

#root {
  min-height: 100vh;
  overflow-x: hidden;
  transition: 0.3s $timing-function;

  &.modal-open {
    filter: blur(8px);
  }

  &.loading {
    filter: blur(8px);
  }
}

body {
  font-family: 'Helvetica Neue World', Arial, Helvetica, sans-serif;
  position: relative;
  background-color: $bg-color;
  color: $white;
  overflow-x: hidden;

  &.burger-open {
    &::before {
      z-index: 3;
    }

    .decorative-element {
      z-index: -1;
    }
  }
}

blockquote {
  line-height: 1.5;
  font-size: 0.875em;
  font-style: italic;
}

.image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  max-height: 100%;

  &-wrapper {
    width: 100%;
    position: relative;
    padding-top: 59.5238095238095%;
  }
}

.social-link {
  transition: 0.3s $timing-function;

  &:hover {
    opacity: 0.8;
  }
}

.w-full {
  width: 100%;
}

.arrow {
  &-wrapper {
    cursor: pointer;
    position: absolute;
    transition: transform 0.3s $timing-function;

    &:hover {
      transform: translate3d(0, 10px, 0);
    }
  }
}

.title {
  font-family: 'Metric', Arial, Helvetica, sans-serif;
  // font-weight: 300;
  font-size: clamp(40px, 7vw, 3.33rem);

  strong {
    font-weight: bold;
  }
}

.bold {
  font-weight: bold;
}

.social-link {
  transition: 0.3s $timing-function;

  &:hover {
    opacity: 0.8;
  }
}

.react-simple-image-viewer {
  &__close {
    transition: 0.3s $timing-function;
    opacity: 0.8;
  }
}

.placeholder {
  width: 90%;
  height: 90%;
  animation: blur 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;

  &-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 1;
    transition: 0.3s $timing-function;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background-color: $black-dark;
    }

    &--hidden {
      opacity: 0;
      z-index: -1;
    }
  }
}

.subtitle {
  display: flex;
  align-items: center;
  color: $yellow;
  text-transform: uppercase;
  font-size: clamp(12px, 2vw, 16px);
  letter-spacing: 4px;
  font-weight: bold;
  line-height: 1.2;

  &::before {
    content: '';
    background-color: $yellow;
    height: 1px;
    width: 55px;
    margin-right: 20px;
  }
}

.text {
  font-family: 'Helvetica Neue World', Arial, Helvetica, sans-serif;
  font-weight: 300;

  strong {
    font-weight: bold;
  }
}

.decorative-element {
  position: fixed;
  left: -90px;
  bottom: -90px;
  z-index: -1;
  opacity: 0.5;
}

.background-image {
  position: absolute;
  filter: grayscale(0.8);
  opacity: 0.7;
  z-index: -1;
}

@media (max-width: 991.98px) {
  .background-image {
    display: none;
  }
}

.container {
  max-width: 1640px;
  margin: 0 auto;
  width: 100%;
}

.link {
  text-decoration: none;
  color: $white;
  position: relative;
  padding: 10px 0;
  cursor: pointer;
  white-space: nowrap;
  font-weight: bold;
  transition: color 0.3s $timing-function;
  font-family: 'Helvertica Neue World', Arial, Helvetica, sans-serif;

  &::before {
    position: absolute;
    width: 100%;
    height: 2px;
    background: $yellow;
    bottom: -10px;
    left: 0;
    content: '';
    pointer-events: none;
    transition: all 0.3s $timing-function;
    opacity: 0;
  }

  &:hover {
    color: $yellow;

    &:before {
      bottom: 0;
      opacity: 1;
    }
  }
}

.torus--wrapper {
  display: flex;
  justify-content: center;
  position: relative;
}

.torus--wrapper:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(/assets/images/all-movieshots.webp);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.5;
  filter: grayscale(0.9);
}
