.movie-shot {
    &-wrapper {
        display: flex;
        flex-direction: column;
    }
    
    &__info {
        font-size: clamp(14px, .7rem, 16px);
        color: $white;
        opacity: .5;
        margin: 0;
        line-height: 1;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    &__tags {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 15px;
        max-height: 150px;
        overflow-y: scroll;
        padding-right: 10px;
        
        opacity: 0;
        transform: translateY(40px);
        transition: .6s $timing-function;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $black-dark;
            border-radius: 5px;
        }
    }

    &__tag {
        border: 1px solid $black-dark;

        &-label {
            color: $black-dark;
        }

        &-value {
            color: $black-dark;
        }
    }

    &__image {
        &-wrapper {}
    }
}