.airdrops {
  min-height: calc(100vh - 400px);
  margin-top: 125px;
  margin-bottom: 200px;

  &__button {
    display: flex;
    background-color: #2081e2;
    align-items: center;
    border-radius: 6px;
    transition: 0.3s $timing-function;
    color: $white;
    text-decoration: none;
    &:hover {
      opacity: 0.5;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__label {
    font-family: 'Metric', Arial, Helvetica, sans-serif;
    font-size: 32px;
    line-height: 1;
    margin-bottom: 15px;
  }

  &__icon {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }

  &__center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__hr {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    &::after {
      content: '';
      height: 125px;
      width: 1px;
      background-color: $yellow;
      margin-bottom: 60px;
      margin-top: 110px;
    }
  }

  .checkpoint__number {
    color: $yellow;
    margin-top: 65px;
  }

  .small {
    font-size: clamp(14px, 0.7rem, 16px);
    color: #fff;
    opacity: 0.7;
    line-height: 1;
    margin-bottom: 0.5rem;
    max-width: 420px;
    text-align: center;
  }

  .checkpoint__image {
    height: 250px;
    object-fit: contain;
  }

  .style_button {
    border: 1px solid $yellow;
    padding: 10px;
    background-color: unset;
    color: $white;
    text-transform: uppercase;
    font-size: clamp(16px, 2vw, 21px);
    margin: 25px 0;
    transition: 0.3s $timing-function;
    cursor: pointer;
    max-height: unset;

    &:hover {
      background: $yellow;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.3;
      background-color: $grey;
      color: $white;
    }
  }
}
