.vote__promotial__text {
  color: $white;
  margin-top: 125px;
  font-family: "Metric", Arial, Helvetica, sans-serif;
  font-size: clamp(40px, 7vw, 3.33rem);
  text-align: center;
}

.vote__wrapper{
  background-color: $white;
  color: $black;
  font-size: 16px;
  border-radius: 25px;
  display: flex;
  margin: 35px 0 0;
  padding: 20px;
  justify-content: space-between;

  &__tablet {
    flex-direction: column;
    align-items: center;

    & div.vote__main > * {
      padding: 0.35rem;
    }
  }
}

.vote__image {
  border-radius: 20px;
  max-height: 300px;
}

.vote__main {
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  width: 100%;
}

.vote__description {
  max-width: 600px;
}

.vote__title {
  font-size: clamp(15px, 2.55vw, 28px);
  margin-top: 5px;
}

.vote__ends {
  color: $grey;
  font-weight: 600;
}

.vote__status__wrapper {
  width: 130px;
}

.vote__status {
  background-color: $yellow;
  padding: 7px 10px;
  color: $white;
  border-radius: 5px;
  margin-top: 5px;

  font-size: 15px;
  width: 130px;
  margin-right: 20px;
}

.vote__options {
  display: flex;
  margin-top: 10px;
  &__tablet {
    justify-content: center;
  }

}

.vote__option {
  cursor: pointer;
  border: $yellow 1px solid;
  padding: 15px 25px;
  text-align: center;
  font-size: clamp(12px, 2vw, 20px);
  font-weight: 600;
  text-transform: uppercase;
}

.vote__option:hover {
  color: $yellow;
}
.vote__option:not([aria-disabled]):hover{
  color: black;
}
.vote__option__disable{
  border: gray 1px solid;
  color: gray;
  padding: 15px 25px;
  text-align: center;
  font-size: clamp(12px, 2vw, 20px);
  font-weight: 600;
  text-transform: uppercase;
}

.vote__option__win {
  cursor: pointer;
  border: $yellow 1px solid;
  padding: 15px 25px;
  text-align: center;
  font-size: clamp(12px, 2vw, 20px);
  font-weight: 600;
  text-transform: uppercase;
  background-color: $yellow;
  color: $white;
}



.vote__chose {
  font-size: 34px;
}

.vote__green {
  cursor: pointer;
  background-color: $green;
  text-transform: uppercase;
  font-weight: 600;
  padding: 10px 15px;
  text-align: center;
  margin-right: 10px;
}

.vote__back {
  cursor: pointer;
  text-transform: uppercase;
  border: $black 1px solid;
  font-weight: 600;
  padding: 10px 15px;
  text-align: center;
  margin-right: 10px;
}

.vote__option__text {

}

.vote__vs {
  font-size: 58px;
  margin-left: -10px;
  margin-right: -10px;
  z-index: 2;
}

.vote__container_expired {
  display: flex;
  align-items: center;
  border: $grey 1px solid;
  border-radius: 15px;
  margin-top: 25px;
  padding: 20px;
}

.vote__title__confirm {
  font-size: clamp(15px, 2.55vw, 22px);
  margin-top: 5px;
}

.vote__title_expired {
  font-size: clamp(15px, 2.55vw, 20px);
  text-transform: uppercase;
  color: $grey;
  font-weight: 600;
  width: 100%;
  text-align: start;
}

.vote__cross__expired {
  opacity: 0.5;
  width: 25px;
  height: 25px;
  margin-right: 20px;
}

.vote__status__expired {
  font-size: 15px;
  padding: 7px 10px;
  color: $grey;
  border: $grey 1px solid;
  border-radius: 5px;
  margin-top: 5px;
  width: 130px;
  margin-right: 20px;
}

.margin__bottom {
  margin-bottom: 75px;
}
