.last-minted {
    margin-bottom: 145px;
    margin-top: 195px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__subtitle {
        margin-bottom: 45px;
    }

    &__navigation {
        &-button {
            background-color: unset;
            border: none;
            outline: none;
            transition: .3s $timing-function;
            cursor: pointer;

            &:hover {
                opacity: .5;
            }

            &-icon {
                color: $white;
                font-size: 20px;
            }
        }
    }

    &__slider {
        margin-top: 70px;
    }

    &__slide {
        max-width: 510px;
        width: 100%;
        margin: 0 15px;
    }
}

.last-minted-container {
    width: 100vw;
    min-width: 100vw;
}