.button {
  cursor: pointer;
  border: 2px solid transparent;
  font-size: 0.83rem;
  font-weight: 500;
  padding: 12px 40px;
  text-transform: uppercase;
  background: unset;
  position: relative;
  font-family: Poppins, Arial, Helvetica, sans-serif;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.3s $timing-function;
  max-height: 54px;

  a {
    text-decoration: inherit;
  }

  &--big {
    font-size: clamp(25px, 7vw, 1.6rem);
    font-weight: bold;
    line-height: 1;
    padding: clamp(20px, 7vw, 30px) clamp(30px, 7vw, 45px);
    text-transform: uppercase;
    border: 1px solid $white;
    color: $white;
    max-height: unset;

    a {
      color: $white;
    }
  }

  &--light {
    color: $white;
    border-color: $white;

    a {
      color: $white;
    }

    path {
      fill: $white;
    }
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  &--full {
    width: 100%;
  }

  &--golden {
    color: $yellow;
    border-color: $yellow;

    a {
      color: $yellow;
    }

    path {
      fill: $yellow;
    }

    &.filled {
      color: $black;
      border-color: $yellow;
      background-color: $yellow;

      a {
        color: $black;
      }

      path {
        fill: $black;
      }
    }

    &.text-white {
      color: $white;
    }
  }

  &--green {
    color: $white;
    background-color: $green;

    a {
      color: $white;
    }

    path {
      fill: $white;
    }
  }

  &--hidden {
    display: none;
  }

  &--red {
    color: $white;
    background-color: $red;

    a {
      color: $white;
    }

    path {
      fill: $white;
    }
  }

  &--dark {
    color: $black;
    border-color: $black;

    a {
      color: $black;
    }

    path {
      fill: $black;
    }
  }

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    color: $grey;
    border-color: $grey;
    cursor: default;

    a {
      color: $grey;
    }

    path {
      fill: currentColor;
    }
  }
}
