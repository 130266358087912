.pagination-container {
  display: flex;
  list-style-type: none;

  .pagination-item {
    padding: 0 14px;
    height: 40px;
    text-align: center;
    margin: auto 4px;
    color: white;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 20px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 40px;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: rgba(224, 180, 114, 0.8);
      cursor: pointer;
    }

    &.selected {
      background-color: rgba(224, 180, 114);
    }

    .pagination-arrow {
      &::before {
        position: relative;
        content: '';
        display: inline-block;
        width: 0.5em;
        height: 0.5em;
        border-right: 0.12em solid rgba(255, 255, 255, 0.87);
        border-top: 0.12em solid rgba(255, 255, 255, 0.87);
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .pagination-arrow::before {
        border-right: 0.12em solid rgba(255, 255, 255, 0.43);
        border-top: 0.12em solid rgba(255, 255, 255, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .pagination-container {
    .pagination-item {
      margin: auto 1px;
    }
  }
}
