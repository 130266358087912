.mint-modal {
  display: flex;
  flex-direction: column;
  background-color: $white;
  color: $black;

  box-shadow: 0 3px 6px rgba($black-dark, 0.16);
  border-radius: 6px;
  padding: 40px;
  max-height: 500px;
  max-width: 600px;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;

  &-auto-height {
    height: auto;
  }

  &__bold {
    font-weight: bold;
  }

  &__link {
    display: inline;
    align-items: center;
    color: $yellow;
    padding: 0;
    margin-left: 5px;

    &--positioned {
      position: relative;
      bottom: -4px;
    }

    &-icon {
      margin-right: 5px;
    }
  }

  &__line-through {
    text-decoration: line-through;
  }

  &-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &::before {
      position: fixed;
      content: '';
      z-index: -1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      filter: blur(4px);
    }
  }

  &__loader {
    position: absolute;
    right: 0;
    top: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }

  &__input {
    text-align: center;
    margin-right: 20px;
    font-size: 16px;
    width: 110px;
    height: 60px;
    padding: 0 20px;
    font-size: 40px;
    font-weight: bold;
    border-radius: 6px;
    border: 2px solid $black-dark;

    &--hasError {
      border-color: $red;
    }

    &-content {
      display: flex;
      align-items: center;
    }

    &-text {
      font-weight: light;
      font-size: clamp(14px, 2vw, 16px);
      line-height: 1.5;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
    }

    &-error {
      color: $red;
      margin-top: 20px;
      font-weight: bold;
    }

    &--gold {
      text-align: left;
      border: none;
      border-bottom: 2px solid $yellow;
      font-weight: normal;
      border-radius: unset;
    }

    &--full {
      width: 100%;
      margin: 0px;
    }

    &--medium {
      height: 36px;
      font-size: 20px;
    }
  }

  &__text {
    margin-top: 20px;

    &--uppercased {
      text-transform: uppercase;
    }
  }

  &__currency {
    font-size: clamp(10px, 2vw, 12px);
    font-weight: normal;
  }

  &__price {
    display: flex;
    flex-direction: row;
    padding: 0.25rem 0;
    font-size: clamp(14px, 2vw, 16px);
    line-height: 1.5;

    &-title {
      flex: 1;
    }

    &-value {
      flex: 2;
    }
  }

  &__buy-eth {
    margin-top: 20px;
    width: 100%;

    &--uppercased {
      text-transform: uppercase;
    }
  }

  &__list {
    list-style: disc;
    position: relative;
    left: 30px;
    margin-bottom: 20px;
    margin-top: 10px;

    &-item {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &__checkbox {
    border: 1px solid $black-dark;
    width: 30px;
    height: 30px;
    border-radius: 2px;
    cursor: pointer;

    &-wrapper {
      display: flex;
      align-items: center;
      margin-top: 32px;
      margin-bottom: 26px;
    }

    &-text {
      font-size: 16px;
      margin-left: 25px;
    }
  }

  &__consent {
    font-size: 14px;

    a {
      color: black;
    }
  }

  &__decorative-element {
    position: absolute;
    bottom: -50%;
    right: 0;
    z-index: -1;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .decor {
      width: 450px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__header-btn-only {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }

  &__title {
    line-height: 1;
    font-size: clamp(25px, 7vw, 40px);
  }

  &__button {
    text-transform: uppercase;
    border: none;
    cursor: pointer;

    transition: color 0.3s $timing-function,
      background-color 0.3s $timing-function;
    padding: 20px;
    background: $white;
    border: 1px solid $black-dark;
    text-decoration: none;
    color: $black;

    &:hover {
      background-color: $black-dark;
      color: $white;
    }

    &-icon {
      transition: 0.3s $timing-function;

      &:hover {
        opacity: 0.5;
      }
    }

    &_connect {
      margin: 0.7rem 0;
    }

    &--full {
      margin-top: 0.7rem;
      width: 100%;
    }

    &--close {
      padding: 0;
      border: none;

      &:hover {
        color: $black-dark;
        background-color: unset;
      }
    }

    &--submit {
      margin-top: 27px;
      padding: 20px;
      font-size: 16px;
      font-weight: bold;
      background-color: $green;
      border: none;

      &:hover {
        background-color: rgba($green, 0.8);
      }
    }

    &--no-margin {
      margin-top: 0;
    }

    &--disabled {
      pointer-events: none;
      opacity: 0.3;
      background-color: $grey;
      color: $white;
    }
  }

  &__subtitle {
    display: flex;
    align-items: center;
    color: $yellow;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;

    &::before {
      content: '';
      margin-right: 20px;
      height: 1px;
      width: 55px;
      background-color: $yellow;
    }
  }

  &--submit {
    &.mint-modal {
      height: auto;
      max-height: unset;
    }
  }

  &--pre-sale,
  &--free-mint {
    &.mint-modal {
      height: auto;
      &::before,
      &::after {
        position: absolute;
        text-transform: uppercase;
        color: rgba($yellow, 0.15);
        font-weight: bold;
        font-size: 136px;
        white-space: nowrap;
        line-height: 1;
        z-index: -1;
      }

      &::before {
        top: -30px;
        left: 0;
      }

      &::after {
        bottom: 10px;
        left: 0;
      }
    }
  }

  &--pre-sale {
    &.mint-modal {
      max-height: unset;

      &__input-wrapper {
        margin-bottom: 20px;
      }

      &::before,
      &::after {
        content: 'PRE SALE';
      }
    }
  }

  &--free-mint {
    &.mint-modal {
      &::before,
      &::after {
        content: 'FREE MINT';
      }
    }
  }

  &--error {
    .mint-modal {
      &__body {
      }

      &__button:not(.mint-modal__button--close) {
        margin-top: 150px;
      }

      &__subtitle {
        color: $red;

        &::before {
          background-color: $red;
        }
      }
    }
  }

  &--approve {
    .mint-modal {
      &__body {
        justify-content: space-between;
      }

      &__button:not(.mint-modal__button--close) {
        margin-top: 25px;
      }
    }
  }

  &--processing {
    .mint-modal {
      &__content {
        height: 100%;
        justify-content: space-between;
      }

      &__text {
        margin-top: 30px;
        width: 70%;
        line-height: 1.5;
        font-size: 16px;
      }
    }
  }
}
