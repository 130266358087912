.cookies-modal {
    display: flex;
    position: fixed;
    z-index: 100;
    background-color: $white;
    bottom: 50px;
    left: 50%;
    width: 80%;
    transform: translate3d(-50%, 0, 0);

    &__logo {
        &-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $black-dark;
            padding: 0 40px;
        }
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 40px;
    }

    &__text {
        color: $black;
        font-size: 14px;
        line-height: 1.5;
        margin-right: 40px;
    }

    &__button {
        background-color: $green;
        color: $white;
        white-space: nowrap;
    }
}