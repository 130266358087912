.card {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 1rem;
  background-color: $white;
  color: $black;

  &__torus {
    border: 2px solid $yellow;
    border-radius: 0;
    color: $white;
    opacity: 1;
    background-color: $black-1;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1.5rem;
    margin: 6rem 0;
    width: 85%;

    .icon {
      height: 3rem !important;
    }
  }

  h2 {
    font-family: 'Metric', Arial, Helvetica, sans-serif;
    font-size: clamp(1.75rem, 2rem, 2.75rem);
  }

  .title--wrapper {
    display: flex;
    flex-grow: 1;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;

    &-center {
      justify-content: center;
    }
  }

  .torus-logo {
    display: inline-flex;
    margin-left: 0.7rem;
    vertical-align: middle;
    height: 2.2rem;
  }

  .image--wrapper {
    display: flex;
    flex-grow: 0;

    img {
      width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  .nft-video {
    width: 100%;
    object-fit: contain;
    object-position: center;
  }

  .textblock-wrapper {
    display: flex;
    flex-flow: column;
    gap: 0.75rem;
  }

  .text-block {
    margin-bottom: 1.5rem;
    line-height: 1.5;
  }

  .button--container {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .icon {
    height: 1.75rem;
    width: auto;
    min-width: 1.75rem;
  }

  @media (min-width: 767.98px) {
    .image--wrapper,
    .nft-video {
      height: 300px;
    }

    &__torus {
      width: 60%;
    }
  }
}
